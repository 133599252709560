import { ComponentProvider } from "../util";

export const MuiCheckbox: ComponentProvider = () => ({
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
});
