/* eslint-disable max-lines */
import debug from "debug";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dbg = debug("fiveable:frontend:rootReducer");

/**
 * Data related to a session.
 * Will be reset when a user leaves a session.
 */
const initialSessionState = {};

/**
 * General app data.
 * Won't be reset when a user leaves a session.
 */
const initialAppState = {
  isProfileLoading: true,
  profile: null,
  refreshModal: false,
  authModal: null,
  expandedModalType: null,
  expandedModalDefaultView: "signup",
  libraryThemeMode: "light",
  sidebarLeftOpen: false,
  myProgress: {
    answerHistory: [],
  },
};

const initialState = { ...initialSessionState, ...initialAppState };

// eslint-disable-next-line max-lines-per-function, @typescript-eslint/default-param-last
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_LIBRARY_THEME_MODE": {
      const { theme } = action.payload;
      return {
        ...state,
        libraryThemeMode: theme,
      };
    }

    // USER PROFILE
    case "UPDATE_PROFILE":
      return {
        ...state,
        isProfileLoading: false,
        profile: action.payload,
      };
    case "UPDATE_PROFILE_LOADING":
      return {
        ...state,
        isProfileLoading: action.payload,
      };

    // AUTH MODALS
    case "SHOW_AUTH_MODAL":
      return { ...state, authModal: action.payload };

    case "HIDE_AUTH_MODAL":
      return { ...state, authModal: null };

    case "SHOW_EXPANDED_MODAL":
      return {
        ...state,
        authModal: action.payload.modal,
        expandedModalType: action.payload.expandedModalType,
        expandedModalDefaultView: action.payload.expandedModalDefaultView,
        authModalButtonLabel: action.payload.label || "",
      };

    case "SHOW_REFRESH_MODAL":
      return { ...state, refreshModal: true };

    case "HIDE_REFRESH_MODAL":
      return { ...state, refreshModal: false };

    case "TOGGLE_SIDEBAR_LEFT":
      return { ...state, sidebarLeftOpen: action.payload.showCTA };

    case "SET_USER_ANSWER_HISTORY":
      return {
        ...state,
        myProgress: {
          ...state.myProgress,
          answerHistory: action?.payload?.answerHistory ?? [],
        },
      };

    case "ADD_ANSWER_TO_USER_HISTORY": {
      const { answer } = action.payload;
      return {
        ...state,
        myProgress: {
          ...state.myProgress,
          answerHistory: [...(state?.myProgress?.answerHistory ?? []), answer],
        },
      };
    }

    default:
      return state;
  }
};

export { initialState, rootReducer };
