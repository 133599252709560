import { IProfileBrowser } from "@hours/utilities";
import { useMemo } from "react";
import useMailerLiteService from "@/services/useMailerLiteService";

import { ISubjectAutocompleteSubject } from "@/types";
import useApiClient, { useShowApiError } from "./useApiClient";
import { useProfileActions } from "../utils/useProfile";
import { useAuthModals } from "../hooks/modals/useAuthModals";

const LOGID = "fiveable:frontend:services:useAuthService";

export interface LoginBody {
  username: string;
  password: string;
  invoiceId?: string;
}

interface LoginProps {
  body: LoginBody;
  cta?: string;
  skipUpdate?: boolean;
}

export interface SignUpBody {
  name: string;
  email: string;
  password: string;
  emailPref: boolean;
  subjects?: ISubjectAutocompleteSubject[];
  subjectsAndExams?: {
    [key: string]: string[];
  };
  invoiceId?: string;
}

export interface LoginSignupResponse {
  profile: IProfileBrowser;
  redeemedInvoice?: boolean;
  invoiceRedemptionAttempted?: boolean;
}

export type AuthService = {
  login: ({ body, cta, skipUpdate }: LoginProps) => Promise<LoginSignupResponse>;
  signUp: (body: SignUpBody, skipUpdate?: boolean) => Promise<LoginSignupResponse>;
  logout: () => void;
};

function useAuthService(): AuthService {
  const { removeProfileAction, updateProfileAction } = useProfileActions();
  const { showLoginModal } = useAuthModals();
  const { apiClient } = useApiClient(showLoginModal);
  const { showApiError } = useShowApiError({ sendToSentry: false, logId: LOGID });
  const { saveSubscriptor } = useMailerLiteService();

  return useMemo(
    () => ({
      login: async ({ body, cta, skipUpdate = false }: LoginProps): Promise<LoginSignupResponse> => {
        const response = (
          await apiClient.post("/auth/login", body, {
            metadata: { handleErrors: "Invalid login credentials." },
          })
        )?.data;
        const { invoiceRedemptionAttempted, redeemedInvoice, profile } = response;
        /**
         * Updating the user's profile is skipped on login and sign up for session pages.
         * Since session pages are reloaded the getProfile call in _app will populate the redux store.
         */
        if (!skipUpdate) {
          updateProfileAction(profile);
          saveSubscriptor(profile, cta, null, null, null, null);
        }

        return { invoiceRedemptionAttempted, redeemedInvoice, profile };
      },
      signUp: async (body: SignUpBody, skipUpdate = false): Promise<LoginSignupResponse> => {
        const response = (
          await apiClient.post("/auth/sign-up", body, {
            metadata: { handleErrors: showApiError },
          })
        )?.data;
        const { invoiceRedemptionAttempted, redeemedInvoice, profile } = response;

        /**
         * Updating the user's profile is skipped on login and sign up for session pages.
         * Since session pages are reloaded the getProfile call in _app will populate the redux store.
         */
        if (!skipUpdate) updateProfileAction(profile);
        return { invoiceRedemptionAttempted, profile, redeemedInvoice };
      },
      logout: async () => {
        await apiClient.post("/auth/logout");
        removeProfileAction();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}

export default useAuthService;
