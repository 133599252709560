import {
  createDispatchHook,
  createSelectorHook,
  createStoreHook,
  ReactReduxContext,
  TypedUseSelectorHook,
} from "react-redux";
import { createContext } from "react";
import { RootState } from ".";

export const modernStoreContext = createContext<RootState>({});
export const legacyStoreContext = ReactReduxContext;

// These should be used instead of plain `useDispatch` and `useSelector` to make sure you
// get all the TypeScripty goodness.
export const useModernDispatch = createDispatchHook<RootState>(modernStoreContext);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useModernSelector: TypedUseSelectorHook<RootState> = createSelectorHook(modernStoreContext);
export const useModernStore = createStoreHook<RootState>(modernStoreContext);

// The default store, which is what these use, is ReactReduxStore, as created in `../rootReducer.js`.
// These are just to make it more obvious that we're using the legacy store.
export const useLegacyDispatch = createDispatchHook(legacyStoreContext);
export const useLegacySelector = createSelectorHook(legacyStoreContext);
