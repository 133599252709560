import MuiButton from "@mui/material/Button";
import MuiDialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiTypography from "@mui/material/Typography";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";

export function ClientUpdateRequiredModal(): JSX.Element {
  const modalIsVisible = useSelector((state: { refreshModal: boolean }) => state.refreshModal);
  // This is intended for future expansion - will allow setting an { updateDetails: {} } object
  // in the common/constants.ts file which will be fetched by the frontend prior to displaying
  // the modal.  It will require creating an endpoint from which the frontend can retrieve the
  // update details, and a call to that endpoint before the modal is displayed.
  const updateDetails = useSelector(
    (state: { updateDetails: { title?: string; text?: string; changelogLink?: string } }) =>
      state.updateDetails
  );

  function doReload() {
    window.location.reload();
  }

  function doWhatsNew() {
    window.open(updateDetails?.changelogLink || "https://fiveable.me");
  }

  return (
    <MuiDialog
      open={modalIsVisible}
      PaperProps={{
        sx: {
          width: "500px",
          minWidth: "300px",
        },
      }}>
      <MuiDialogTitle>
        <MuiTypography component="span" variant="h4" sx={{ fontWeight: "600" }}>
          {updateDetails?.title || "We've Updated!"}
        </MuiTypography>
      </MuiDialogTitle>
      <MuiDialogContent sx={{ display: "flex", alignItems: "center" }}>
        <p style={{ width: "70%" }}>
          {updateDetails?.text || "Please refresh the page to update to the latest version."}
        </p>
        <div
          style={{
            minHeight: "150px",
            width: "100%",
            maxWidth: "150px",
            background: "url(/img/mascot/confetti_turtle.svg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
          }}>
          &nbsp;
        </div>
      </MuiDialogContent>
      <MuiDialogActions
        sx={{ backgroundColor: "#ffffffaa", "& .MuiButton-root": { fontSize: "0.9rem", ml: 2 } }}>
        <MuiButton color="primary" variant="outlined" onClick={doWhatsNew}>
          What&apos;s New?
        </MuiButton>
        <MuiButton color="primary" variant="contained" onClick={doReload}>
          Refresh Now
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
}
