import { useState, useEffect } from "react";

let hiddenDiv = null;
let setDivTextForGSCGlobal = null;

// Hook used to create a div with id="gsc-widget" and ensures only one instance
// of the div lives on the page.
// In GetSiteControl, targetting settings are used to only display
// the widgets based on the value of this div.
// The value set here matters for subsequent page loads because timing of the value changing
// and the timing of the GSC logic executing can conflict or cause race cases.
// After much QA, having the check be "If #gsc-widget is less than 0" works consistently as expected.
//
// To prevent GSC widgets from loading do the following:
// 1. "Display widget it" section -> HTML condition #gsc-widget less than 1
// 2. "Start displaying widget" section -> after the user spends 2 seconds on the page (if race cases exist)
export const useHiddenDivForGSC = (): ((text: string | number) => void) => {
  const [, setDivTextForGSC] = useState("");

  useEffect(() => {
    if (!hiddenDiv) {
      hiddenDiv = document.getElementById("gsc-widget");

      if (!hiddenDiv) {
        hiddenDiv = document.createElement("div");
        hiddenDiv.id = "gsc-widget";
        hiddenDiv.style.display = "none";
        document.body.appendChild(hiddenDiv);
      }
    }
  }, []);

  useEffect(() => {
    if (!setDivTextForGSCGlobal) {
      setDivTextForGSCGlobal = setDivTextForGSC;
    }
  }, [setDivTextForGSC]);

  const setHiddenDivText = (text) => {
    if (hiddenDiv) {
      hiddenDiv.textContent = text.toString();
    }
    setDivTextForGSCGlobal(text);
  };

  return setHiddenDivText;
};
