/* eslint-disable import/no-import-module-exports */
/**
 * eslint flags the access to `module.hot` below as old-style module exports, so
 * we have to disable the rule for the file to stop it from complaining about
 * imports.
 */
import { useMemo } from "react";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { initialState, rootReducer } from "./rootReducer";

let legacyStore;

function initStore(preloadedState = initialState) {
  const composeEnhancers = composeWithDevTools({ name: "legacy redux store, plz kill me" });
  if (process.env.NODE_ENV === "production") return createStore(rootReducer, preloadedState);
  return createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(thunk)));
}

const initializeStore = (preloadedState) => {
  let initializedStore = legacyStore ?? initStore(preloadedState);

  // for hot reloading changes in rootReducer
  // still, any changes in this file will still need hard reload
  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./rootReducer", () => initializedStore.replaceReducer(rootReducer));
  }

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && legacyStore) {
    initializedStore = initStore({
      ...legacyStore.getState(),
      ...preloadedState,
    });
    // Reset the current store
    legacyStore = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return initializedStore;
  // Create the store once in the client
  if (!legacyStore) legacyStore = initializedStore;

  return initializedStore;
};

function useLegacyStore(initState) {
  return useMemo(() => initializeStore(initState), [initState]);
}

export { initializeStore, useLegacyStore };
