const backendUrl = "http://localhost:3001";
// const backendUrl = "https://serverdev.fiveable.me";
// const backendUrl = "https://serverprod.fiveable.me";

const ioUrl = "http://localhost:3002";
// const ioUrl = "https://iodev.fiveable.me";
// const ioUrl = "https://io.fiveable.me";

const localBaseUrl = "http://localhost:3000";
// add https:// to the front of the public base url from Vercel
const publicBaseUrl = process.env.NEXT_PUBLIC_BASE_URL || process.env.NEXT_PUBLIC_VERCEL_URL;
const baseUrl = publicBaseUrl ? `https://${publicBaseUrl}` : localBaseUrl;
const libraryBaseUrl = `${baseUrl}/library`;

const isDev = process.env.NODE_ENV !== "production";
const algoliaIndexEnv = isDev ? "dev" : "prod";

const libraryRootUrl = isDev ? localBaseUrl : `https://library.${publicBaseUrl}`;

const isCrawler =
  typeof window !== "undefined" &&
  window.navigator &&
  /bot|googlebot|crawler|spider|robot|crawling/i.test(
    window && window.navigator && window.navigator.userAgent
  );

export default {
  production: process.env.NEXT_PUBLIC_IS_PRODUCTION || false,
  backendUrl:
    process.env.NEXT_PUBLIC_BACKEND_URL ||
    process.env.NEXT_PUBLIC_PRODUCTION_BACKENDURL ||
    process.env.NEXT_PUBLIC_STAGING_BACKENDURL ||
    backendUrl,
  ioUrl:
    process.env.NEXT_PUBLIC_IO_URL ||
    process.env.NEXT_PUBLIC_PRODUCTION_IOURL ||
    process.env.NEXT_PUBLIC_STAGING_IOURL ||
    ioUrl,
  baseUrl,
  isDev,
  algoliaIndexEnv,
  libraryBaseUrl, // TODO: deprecate this, it's wrong
  libraryRootUrl,
  isCrawler,
};
