// eslint-disable-next-line import/no-named-default
import { default as amplitude } from "amplitude-js";
import { Experiment } from "@amplitude/experiment-js-client";
// import Router from "next/router";
import { hasCramMode } from "@hours/utilities";
import { formatSubjectsAndExams } from "./analytics.utils";
import { setGscUser } from "./Library/documentScripts";

const AMPLITUDE_EXPERIMENT_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENT_API_KEY;
const AMPLITUDE_API_ENDPOINT = process.env.NEXT_PUBLIC_AMPLITUDE_API_ENDPOINT;
const LOG_EVENTS = process.env.NEXT_PUBLIC_LOG_ANALYTICS_EVENTS;

const DEV_HOST_NAMES = ["localhost", "library.dev.fiveable.me", "dev.fiveable.me"];

export const getWindowIsUndefined = () => typeof window === "undefined";

const getAmplitudeKey = () => {
  if (getWindowIsUndefined()) return null;
  const hostName = window.location?.hostname;

  if (DEV_HOST_NAMES.includes(hostName)) {
    // eslint-disable-next-line no-console
    console.log("Using dev analytics...");
    return process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY_DEV;
  }

  return process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY_LIBRARY;
};

const formatAction = (action) =>
  action
    .replace("_", " ")
    .split(" ")
    .map((curr) => curr[0].toUpperCase() + curr.slice(1))
    .join(" ");

if (!getWindowIsUndefined()) {
  const amplitudeKey = getAmplitudeKey();
  amplitude.getInstance().init(amplitudeKey, null, {
    includeReferrer: true,
    includeUtm: true,
    apiEndpoint: AMPLITUDE_API_ENDPOINT,
  });
}

export const getAmplitudeSessionId = () => {
  if (getWindowIsUndefined()) return null;
  return amplitude.getInstance().getSessionId();
};

export const getVariant = async (experimentName, fallbackPayload) => {
  // in the event experiment initialization fails or the user is not part of the experiment, the fallback payload will be used
  const fallbackVariant = { value: "fallback", payload: fallbackPayload };
  if (getWindowIsUndefined()) return fallbackVariant;

  try {
    const experiment = Experiment.initializeWithAmplitudeAnalytics(AMPLITUDE_EXPERIMENT_KEY, {
      automaticFetchOnAmplitudeIdentityChange: true,
    });

    // using .fetch() here since it seemed to solve the issue where anonymous users were not being allocated to the experiment
    const fetchedExperiment = await experiment.fetch();
    const variant = fetchedExperiment.variant(experimentName);
    if (variant.value) return variant;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  return fallbackVariant;
};

export const formatPropsToTrackFromProfile = (profile) =>
  !profile
    ? {}
    : {
        id: profile._id,
        displayCurrentSessions: profile.displayCurrentSessions,
        displayFinishedSessions: profile.displayFinishedSessions,
        email: profile.email,
        name: profile.name,
        firstName: profile.name?.split(" ")[0] ?? "",
        surveyed: profile.surveyed, // onboarding survey, Rooms
        subjectsAndExams: formatSubjectsAndExams(profile),
        rolesStringified: JSON.stringify(profile.roles ?? []),
        hasCramMode: hasCramMode(profile),
      };

export const setAnalyticsUser = (profile) => {
  if (!amplitude || getWindowIsUndefined()) return;
  // eslint-disable-next-line consistent-return
  if (!profile) {
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().clearUserProperties(null); // found this here: https://github.com/amplitude/Amplitude-JavaScript/blob/252346961cfd1f1437912971736dacf33882e44d/src/amplitude-snippet.js#L72
    return;
  }

  const themeModeLS = global?.window?.localStorage?.getItem("fiveable-theme-mode");

  const themeMode = themeModeLS?.includes("dark") ? "dark" : "light";

  const propsToTrack = {
    ...formatPropsToTrackFromProfile(profile),
    themeMode,
  };

  // amplitude
  amplitude.getInstance().setUserId(profile._id);
  amplitude.getInstance().setUserProperties(propsToTrack);

  // google
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    userId: profile._id,
  });

  // getsitecontrol
  setGscUser(propsToTrack);
};

// for use when hooks cannot be used or outside of redux provider such as in _app.js for page view tracking. Otherwise use the trackEvent provided through the useTrackEvent hook
export const trackEventJS = ({
  category = "",
  action: actionFromProps,
  label = "",
  nonInteraction = false,
  other = {},
}) => {
  const pageviewAction = "pageview";
  let action = actionFromProps || "";

  if (actionFromProps === "VIEW PAGE") {
    action = pageviewAction;
  }

  let session = {};
  // const sessionId = Router.router?.query?.sessionId;
  const sessionId = window?.location?.query?.sessionId;
  if (sessionId) {
    session = { sessionId };
  }

  const data = {
    event: nonInteraction ? "gaEventNonInteraction" : "gaEvent",
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  };

  // order of session then other is important here so if a new session id comes through "other" along with other session data, they are aligned.
  const expandedData = { ...data, ...session, ...other };

  // Populate sourceUrl on events that don't have it, using window.location.pathname.
  if (!expandedData.sourceUrl && !getWindowIsUndefined()) {
    expandedData.sourceUrl = window?.location?.pathname;
  }

  // library actions were previously formatted this way while rooms actions were not, just keeping consistent. pageview is a special case where it's been aliased on amplitude to View Page and not looking to change that here
  const amplitudeAction = action !== "pageview" ? formatAction(action) : action;

  if (LOG_EVENTS) {
    // eslint-disable-next-line no-console
    console.log("Amplitude Event", { amplitudeAction, expandedData });
  }

  // push event to Amplitude
  if (amplitude) {
    amplitude.getInstance().logEvent(amplitudeAction, expandedData);
  }

  // push event to GA datalayer
  if (getWindowIsUndefined()) return;
  if (action === "pageview" || action === "page-view") {
    data.event = "pageview";
  }
  data.source = expandedData.sourceUrl || window?.location?.pathname;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const addUserProperties = (properties) => {
  if (amplitude) {
    amplitude.getInstance().setUserProperties(properties);
  }
};
