import { ComponentProvider } from "../util";

export const MuiChip: ComponentProvider = (theme) => ({
  MuiChip: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        // Same for small and medium chips
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "18px",
        letterSpacing: "0.16px",
        height: "auto",
        ...(ownerState.size === "small" && {
          borderRadius: theme.borderRadii.SMALL,
          padding: "3px 4px",
        }),
        ...(ownerState.size === "medium" && {
          borderRadius: theme.borderRadii.MEDIUM,
          padding: "4px",
        }),
        ...(ownerState.size === "large" && {
          borderRadius: theme.borderRadii.LARGE,
          padding: "12px 20px",
        }),
      }),
      label: {
        padding: 0,
      },
      deleteIcon: {
        margin: 0,
      },
      // TODO: Confirm with Cami the default styles on chips
      // eslint-disable-next-line
      colorDefault: {
        background: theme.palette.background.default,
        color: theme.palette.text.primary,

        "&:hover": {
          background: theme.palette.background.hover.light,
        },
      },
    },
    variants: [
      {
        props: { variant: "contained" },
        style: {
          backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[600] : theme.palette.common.white,
          color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
          border: theme.palette.mode === "dark" ? `1px solid ${theme.palette.grey[500]}` : "1px solid black",
          boxShadow: `${theme.shadows[1]} !important`,
          "&:hover": {
            backgroundColor:
              theme.palette.mode === "dark" ? theme.palette.grey[600] : theme.palette.common.white,
            color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
            cursor: "default",
            boxShadow: `${theme.shadows[1]} !important`,
          },
        },
      },
    ],
  },
});
