/* eslint-disable */
// TODO: eventually should come back and lint this but today is not that day
import { Profile } from "@/store/api/graphql/generated/types";
import { IProfileBrowser } from "@hours/utilities";
import {
  format,
  formatDistanceToNow,
  getMonth,
  getYear,
  formatISO,
  addSeconds,
  isThisYear,
  differenceInCalendarDays,
} from "date-fns";

export const dateFormat = (d, formatString) => {
  try {
    const date = new Date(toMilSecs(d));
    if (formatString) {
      return format(date, formatString);
    }
    let str = "MMM d, h:mm a";
    if (!isThisYear(date)) str += ", yyyy";
    return format(date, str);
  } catch (err) {
    console.error(err);
  }
};

export const toFormattedDate = (date) =>
  date
    .toLocaleDateString("en-us", {
      timeZone: "UTC",
      month: "long",
      day: "numeric",
      year: "numeric",
    })
    .toLowerCase();

const timeOptions = {
  hour: "numeric",
  minute: "2-digit",
  timeZoneName: "short",
};
export const toFormattedTime = (date) => date.toLocaleTimeString("en-US", timeOptions);

export const dateToTimestamp = (d) => {
  const date = new Date(toMilSecs(d));
  // return format(date, 'yyyy-MM-ddThh:mm');
  return formatISO(date);
};

export const toMilSecs = (date) => {
  if (!date) return null;
  if (date.hasOwnProperty("seconds")) {
    return date.seconds * 1000;
  }
  if (date.hasOwnProperty("_seconds")) {
    return date._seconds * 1000;
  }
  return new Date(date).getTime();
};

export const isEventDateLiveNow = (date) => {
  const ms = toMilSecs(date);
  const hr = 1000 * 60 * 60;
  const now = Date.now();
  return now >= ms && now <= ms + hr * 2;
};

export const isUserOnline = (date) => {
  const ms = toMilSecs(date);
  const min = 1000 * 60;
  const now = Date.now();
  return now >= ms && now <= ms + min * 5;
};

export const toSecs = (date) => toMilSecs(date) / 1000;

export const dateFromNow = (d, strict) => {
  const date = new Date(toMilSecs(d));
  const diff = date - Date.now();

  const fourDays = 1000 * 60 * 60 * 24 * 4;

  if (diff <= fourDays || strict) {
    if (diff <= 0) {
      return `${formatDistanceToNow(date)} ago`;
    }
    return `in ${formatDistanceToNow(date)}`;
  }
  return format(date, "MMM d, h:mm ");
};

export const shortenedDateFromNow = (date) => {
  const dateString = dateFromNow(date);

  if (dateString.includes("minute")) return dateString.replace("minute", "min");
  if (dateString.includes("hour")) return dateString.replace("hour", "hr");
  return dateString;
};

export const dateFromNowForMobile = (d, strict) => {
  const date = new Date(toMilSecs(d));
  const diff = date - Date.now();

  const fourDays = 1000 * 60 * 60 * 24 * 4;

  if (diff <= fourDays || strict) {
    if (diff <= 0) {
      return formatDistanceToNow(date)
        .replace("about", "")
        .replace("less than a", "> 1")
        .replace(" hours", "h")
        .replace(" hour", "h")
        .replace(" days", "d")
        .replace(" day", "d")
        .replace(" minutes", "m")
        .replace(" minute", "m");
    }
    return `in ${formatDistanceToNow(date)}`;
  }
  return format(date, "MMM d, h:mm ");
};

export const lastJune = () => {
  const now = new Date();
  const year = getMonth(now) > 4 ? getYear(now) : getYear(now) - 1;
  return new Date(year, 5, 30);
};

export const nextJune = () => {
  const now = new Date();
  const year = getMonth(now) > 5 ? getYear(now) + 1 : getYear(now);
  return new Date(year, 5, 30, 23, 59);
};

export const cramMonths = ["mar", "apr", "may"];

// Takes any kind of object (event, trivia, content) and converts whatever date objects it can into mil secs representation
export const serializeDatesOnObject = (doc) => {
  const docToReturn = {
    ...doc,
    createdAt: toMilSecs(doc.createdAt),
    updatedAt: toMilSecs(doc.updatedAt),
    timestamp: toMilSecs(doc.timestamp),
    publishDate: toMilSecs(doc.publishDate),
    date: toMilSecs(doc.date),
    endDate: toMilSecs(doc.endDate),
    normalTime: toMilSecs(doc.normalTime),
    time: toMilSecs(doc.time),
    associatedEvent: doc.associatedEvent ? serializeDatesOnObject(doc.associatedEvent) : null,
    exams: doc.exams && doc.exams.length > 0 ? doc.exams.map(serializeDatesOnObject) : null,
    overflowDates:
      doc.overflowDates && doc.overflowDates.length ? doc.overflowDates.map(serializeDatesOnObject) : null,
    lastTrendingDate: toMilSecs(doc.lastTrendingDate),
    endDatetime: toMilSecs(doc.endDatetime),
  };
  if (!doc.associatedEvent) delete docToReturn.associatedEvent;
  if (!doc.exams) delete doc.exams;
  return docToReturn;
};

export const formatLongSeconds = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;

  return (mins ? `${mins}m` : "") + (!!mins && !!secs ? " " : "") + (!!mins && !secs ? "" : `${secs}s`);
};

export const toVideoLength = (seconds) => {
  let secs = seconds % 60;
  const totalMinutes = Math.floor(seconds / 60);
  let mins = Math.floor(totalMinutes % 60);
  const hours = Math.floor(totalMinutes / 60);

  if (mins > 0 && mins < 10 && hours >= 1) {
    mins = `0${mins}`;
  }

  if (secs > 0 && secs < 10) {
    secs = `0${secs}`;
  }

  if (secs === 0) {
    secs = "00";
  }

  if (mins === 0) {
    mins = "00";
  }

  return (hours ? `${hours}:` : "") + (mins ? `${mins}` : "") + (!!mins && !secs ? "" : `:${secs}`);
};

export const isDST = (d) => {
  const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) != d.getTimezoneOffset();
};

export const convertToEST = (d) => {
  const hrs = isDST(d) ? 4 : 5;
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  // This doesn't work for upcoming dates during DST
  return utc + 3600000 * (hrs * -1);
};

export const upcomingDateToET = (date) => {
  return date.toLocaleTimeString("en-US", {
    timeZone: "America/New_York",
    hour: "numeric",
    minute: "2-digit",
  });
};

export const timeString = (d) => {
  const hr = getHours(d);
  const min = getMinutes(d);

  const ampm = hr < 12 ? "am" : "pm";
  let str;

  if (hr === 0) {
    str = "12";
    if (min !== 0) {
      str += `:${min}`;
    }
    str += ampm;
  } else {
    str = hr > 12 ? hr - 12 : hr;
    if (min !== 0) {
      str += `:${min}`;
    }
    str += `${ampm} EST`;
  }
  return str;
};

export const createOrEditDateText = ({
  data,
  creationText = "created",
  editedText = "edited",
  showBoth = false,
  mobile,
}) => {
  const creArr = ["created_at", "createdAt"];
  const ediArr = ["updated_at", "updatedAt", "edited_at", "editedAt"];
  if (!data || !Object.keys(data).some((key) => creArr.includes(key))) return "No date found";

  const creKey = creArr.find((x, i) => Object.keys(data).some((key) => creArr[i] === key));
  const ediKey = ediArr.find((x, i) => Object.keys(data).some((key) => ediArr[i] === key));

  const creDate = data[creKey];
  const ediDate = data[ediKey];

  if (mobile) {
    if (Boolean(ediKey) && new Date(data[ediKey]) > addSeconds(new Date(data[creKey]), 1)) {
      return `${dateFromNowForMobile(data[ediKey])}`;
    }
    return `${dateFromNowForMobile(data[creKey])}`;
  }
  if (showBoth && Boolean(ediKey))
    return `${creationText} ${dateFromNow(creDate)} • ${editedText} ${dateFromNow(ediDate)}`;
  if (Boolean(ediKey) && new Date(data[ediKey]) > addSeconds(new Date(data[creKey]), 1)) {
    return `${editedText} ${dateFromNow(data[ediKey])}`;
  }
  return `${creationText} ${dateFromNow(data[creKey])}`;
};

export const getThreeMinutesAgo = () => {
  const date = new Date();
  return new Date(date.setMinutes(date.getMinutes() - 3));
};

export const getCurrentDayNumber = () => {
  const now = new Date();
  const start = new Date(now.getFullYear(), 0, 0);
  const diff = now - start + (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);

  return day;
};

export const formatDaysUntilCramEvent = (date: string) => {
  const startDate = new Date(date);
  const readableDate = format(startDate, "MMMM d, yyyy • haaa");
  const today = new Date();
  const numDays = differenceInCalendarDays(startDate, today);
  const dayString = numDays === 1 ? "day" : "days";

  let daysUntil = `happening in ${numDays} ${dayString}`;

  if (numDays === 0) daysUntil = "happening today";
  if (numDays < 1) daysUntil = "event ended";

  return { readableDate, daysUntil };
};

export const getJoinedDate = (profile: IProfileBrowser | Profile) => {
  const joinedDate = profile?.joined;
  const formattedDate = joinedDate && format(new Date(joinedDate), "MMMM d yyyy");
  return formattedDate;
};
