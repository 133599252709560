import { Inter, Kanit } from "next/font/google";
import { createTheme, Theme } from "@mui/material/styles";
import { ThemeExtender } from "./util";

export const inter = Inter({
  weight: ["300", "400", "500", "700", "900"],
  display: "swap",
  subsets: ["latin"],
});

export const kanit = Kanit({
  weight: ["400", "500", "600", "700"],
  display: "swap",
  style: ["normal", "italic"],
  subsets: ["latin"],
});

const FONT_FAMILY = [inter.style.fontFamily, "Open Sans", "Arial", "sans-serif"].join(",");
const HEADING_FONT_FAMILY = [kanit.style.fontFamily, FONT_FAMILY].join(",");
const LINE_HEIGHT_120 = "120%";
const LINE_HEIGHT_150 = 1.5;

// eslint-disable-next-line max-lines-per-function
export const createTypography: ThemeExtender = (theme: Theme): Theme =>
  createTheme(theme, {
    typography: {
      h1: {
        fontFamily: HEADING_FONT_FAMILY,
        fontSize: "3rem",
        fontWeight: 700,
        lineHeight: LINE_HEIGHT_120,
        [theme.breakpoints.down("md")]: {
          fontSize: "2rem",
        },
      },
      h2: {
        fontFamily: HEADING_FONT_FAMILY,
        fontSize: "2.5rem",
        fontWeight: 600,
        lineHeight: LINE_HEIGHT_120,
        [theme.breakpoints.down("md")]: {
          fontSize: "1.75rem",
        },
      },
      h3: {
        fontFamily: HEADING_FONT_FAMILY,
        fontSize: "2rem",
        fontWeight: 500,
        lineHeight: LINE_HEIGHT_120,
        [theme.breakpoints.down("md")]: {
          fontSize: "1.5rem",
        },
      },
      h4: {
        fontFamily: HEADING_FONT_FAMILY,
        fontSize: "1.75rem",
        fontWeight: 500,
        lineHeight: LINE_HEIGHT_120,
        [theme.breakpoints.down("md")]: {
          fontSize: "1.375rem",
        },
      },
      h5: {
        fontFamily: HEADING_FONT_FAMILY,
        fontSize: "1.5rem",
        fontWeight: 500,
        lineHeight: LINE_HEIGHT_150,
        [theme.breakpoints.down("md")]: {
          fontSize: "1.25rem",
        },
      },
      h6: {
        fontFamily: HEADING_FONT_FAMILY,
        fontSize: "1.25rem",
        fontWeight: 500,
        lineHeight: LINE_HEIGHT_150,
        [theme.breakpoints.down("md")]: {
          fontSize: "1.125rem",
        },
      },
      subtitle1: {
        fontFamily: FONT_FAMILY,
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: LINE_HEIGHT_120,
        [theme.breakpoints.down("md")]: {
          fontSize: ".75rem",
        },
      },
      subtitle2: {
        fontFamily: FONT_FAMILY,
        fontSize: ".875rem",
        fontWeight: 500,
        lineHeight: LINE_HEIGHT_120,
        [theme.breakpoints.down("md")]: {
          fontSize: ".75rem",
        },
      },
      body1: {
        fontFamily: FONT_FAMILY,
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.6,
        [theme.breakpoints.down("md")]: {
          fontSize: ".875rem",
        },
      },
      body2: {
        fontFamily: FONT_FAMILY,
        fontSize: ".875rem",
        fontWeight: 400,
        lineHeight: 1.6,
        [theme.breakpoints.down("md")]: {
          fontSize: ".75rem",
        },
      },
      caption: {
        fontFamily: FONT_FAMILY,
        fontSize: "0.75rem",
        fontWeight: 400,
        lineHeight: LINE_HEIGHT_120,
        [theme.breakpoints.down("md")]: {
          fontSize: ".625rem",
        },
      },
      overline: {
        fontFamily: FONT_FAMILY,
        fontSize: "0.75rem",
        fontWeight: 400,
        lineHeight: LINE_HEIGHT_120,
        textTransform: "uppercase",
      },
      button: {
        fontWeight: 700,
        fontSize: "inherit",
        letterSpacing: ".02875rem",
        lineHeight: "normal",
        fontFamily: FONT_FAMILY,
      },
      fontColor: theme.palette.text.primary,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightMedium: 500,
      fontWeightRegular: 400,
    },
  });
