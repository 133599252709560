import { useMemo } from "react";
import { IProfileBrowser } from "@hours/utilities";
import { useSnackbar } from "notistack";

// eslint-disable-next-line import/no-cycle
import useApiClient, { isUnauthorizedError, useShowApiError } from "./useApiClient";
import { useProfileActions } from "../utils/useProfile";

export type ProfileService = {
  getProfile: () => Promise<IProfileBrowser>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDashboardInfo: (profileId: string) => Promise<any>;
  deleteProfile: (profileId: string) => Promise<boolean>;
  updateProfile: (
    profileId: string,
    updates: Partial<IProfileBrowser>
  ) => Promise<IProfileBrowser | undefined>;
  addSession: (profileId: string, sessionId: string) => Promise<boolean>;
  removeSession: (sessionId: string) => Promise<boolean>;
  changePassword: (
    userId: string,
    email: string,
    currentPassword: string,
    newPassword: string
  ) => Promise<boolean>;
  forgotPassword: (email: string) => Promise<boolean>;
  resetPassword: (token: string, email: string, password: string) => Promise<boolean>;
  validateToken: (token: string) => Promise<string | undefined>;
  uploadAvatar: (avatarData: File) => Promise<string | undefined>;
};

// eslint-disable-next-line max-lines-per-function
function useProfileService(): ProfileService {
  const { enqueueSnackbar } = useSnackbar();
  const { apiClient } = useApiClient();
  const { showApiError } = useShowApiError({
    sendToSentry: false,
    logId: "fiveable:frontend:services:useProfileService",
  });
  const { setProfileLoadingAction, updateProfileAction } = useProfileActions();

  return useMemo(
    // eslint-disable-next-line max-lines-per-function
    () => ({
      // i know it says i'm adding this in this commit but i'm just here
      // .. merging main so idk why this is my job 😭 dont get mad
      // .. if i break something
      // eslint-disable-next-line consistent-return
      getProfile: async (): Promise<IProfileBrowser> => {
        try {
          const profile = (
            await apiClient.get("/profile/@me/", {
              // Skip error reporting if this returns a 401 since that will happen
              // every time a session cookie is missing or invalid
              headers: {
                "x-skip-error-reporting": "true",
              },
              metadata: { skipErrorReporting: (err) => isUnauthorizedError(err) },
            })
          )?.data;
          updateProfileAction(profile);
          return profile;
        } catch (err) {
          setProfileLoadingAction(false);
        }
      },

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getDashboardInfo: async (profileId: string): Promise<any> => {
        return (
          await apiClient.get("/profile/get-dashboard-info/", {
            params: { id: profileId },
            metadata: {
              handleErrors: "There was an error fetching your dashboard data.",
            },
          })
        )?.data;
      },

      deleteProfile: async (profileId: string): Promise<boolean> => {
        await apiClient.post(
          "/profile/delete-profile",
          { _id: profileId },
          { metadata: { handleErrors: "There was an error deleting your profile." } }
        );
        return true;
      },

      updateProfile: async (
        profileId: string,
        updates: Partial<IProfileBrowser>
      ): Promise<IProfileBrowser | undefined> => {
        const profile = (
          await apiClient.post(
            "/profile/update-profile",
            { _id: profileId, ...updates },
            {
              metadata: { handleErrors: showApiError },
            }
          )
        )?.data;
        updateProfileAction(profile);
        return profile;
      },

      addSession: async (profileId: string, sessionId: string): Promise<boolean> => {
        return (
          await apiClient.post(
            "/profile/add-session",
            { id: profileId, sessionId },
            { metadata: { handleErrors: "There was an error adding your session." } }
          )
        )?.data;
      },

      removeSession: async (sessionId: string): Promise<boolean> => {
        return (
          await apiClient.post(
            "/profile/remove-session",
            { sessionId },
            { metadata: { handleErrors: "There was an error removing your session." } }
          )
        )?.data;
      },

      changePassword: async (
        userId: string,
        email: string,
        currentPassword: string,
        newPassword: string
      ): Promise<boolean> => {
        return (
          await apiClient.post(
            "/password/change-password",
            { _id: userId, email, currentPassword, newPassword },
            { metadata: { handleErrors: showApiError } }
          )
        )?.data;
      },

      forgotPassword: async (email: string): Promise<boolean> => {
        return (await apiClient.post("/password/forgot-password", { email }))?.data;
      },

      resetPassword: async (token: string, email: string, password: string): Promise<boolean> => {
        return (await apiClient.post("/password/reset-password", { accessId: token, email, password }))?.data;
      },

      validateToken: async (token: string): Promise<string | undefined> => {
        return (await apiClient.get("/password/validate-access-id", { params: { accessId: token } }))?.data;
      },

      // eslint-disable-next-line consistent-return
      uploadAvatar: async (avatarData: File): Promise<string | undefined> => {
        if (avatarData) {
          const data = new FormData();
          data.append("file", avatarData);

          const response = (
            await apiClient.post("/upload", data, {
              metadata: {
                handleErrors: (err) => {
                  const errMsg = err?.response?.status !== 200 && err?.response?.data?.message;
                  enqueueSnackbar(errMsg || "There was an error uploading your profile photo.", {
                    variant: "error",
                  });
                },
              },
            })
          )?.data;

          if (response) {
            return `https://d2l19zrsusm3xd.cloudfront.net/${response.name}`;
          }
          return undefined;
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}

export default useProfileService;
