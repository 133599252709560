import Cookies from "js-cookie";
import { useEffect, useState, useCallback } from "react";
import { sendSentryException } from "@/utils/sentry";

// WHEN TESTING IN DEV - the amplitude key should now switch automatically but if you're running into problems not seeing the variant you expect, check the amplitudeExperimentMiddleware.ts file to make sure the env var is correct

export enum ABVariant {
  CONTROL = "control",
  TREATMENT = "treatment",
  VARIANT1 = "variant1",
  VARIANT2 = "variant2",
  VARIANT3 = "variant3",
  VARIANT4 = "variant4",
  VARIANT5 = "variant5",
  VARIANT6 = "variant6",
}

export enum FlagVariant {
  ON = "on",
  OFF = "off",
}

type Variant = `variant${string}`;

type FlagValue = "on" | "off";

export type Experiment<T = { [x: string]: unknown }> = {
  value: ABVariant | Variant | FlagValue;
  payload?: T;
};
interface IuseABTestProps<T = { [x: string]: unknown }> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fallbackVariant?: {
    value: ABVariant | Variant | FlagValue;
    payload?: T;
  };
  experimentKey: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useABTest = <T>({
  fallbackVariant = { value: ABVariant.CONTROL },
  experimentKey,
}: IuseABTestProps<T>): Experiment<T> => {
  const [variant, setVariant] = useState<Experiment<T>>(fallbackVariant);

  const setAndSaveVariant = useCallback(
    (newVariant: Experiment<T>) => {
      setVariant(newVariant);
      sessionStorage.setItem(`ab_${experimentKey}`, JSON.stringify(newVariant));
    },
    [experimentKey]
  );

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    // Check URL parameter
    const urlParams = new URLSearchParams(window.location.search);
    const urlVariant = urlParams.get(`ab_${experimentKey}`);
    if (urlVariant) {
      setAndSaveVariant({ value: urlVariant as ABVariant | Variant | FlagValue });
      return;
    }

    // Check sessionStorage
    const storedVariant = sessionStorage.getItem(`ab_${experimentKey}`);
    if (storedVariant) {
      try {
        const parsedVariant = JSON.parse(storedVariant);
        setAndSaveVariant(parsedVariant);
        return;
      } catch (e) {
        sendSentryException(e, {
          tags: { error: "useABTest.ts: storedVariant failed to parse" },
          context: { storedVariant },
        });
      }
    }

    // Fallback to cookie-based variant
    const abVariants = Cookies.get("experimentVariants");
    if (abVariants) {
      try {
        const variants = JSON.parse(abVariants);
        const variantFromCookie: Experiment<T> = variants[experimentKey];
        if (variantFromCookie) setAndSaveVariant(variantFromCookie);
      } catch (e) {
        sendSentryException(e, {
          tags: { error: "useABTest.ts: variantFromCookie failed to parse" },
          context: { abVariants },
        });
      }
    }
  }, [experimentKey, setAndSaveVariant]);

  return variant;
};

export default useABTest;
