import { createTheme, Theme } from "@mui/material/styles";
import { ThemeExtender } from "../util";

export const white = "#ffffff";
export const black = "#171717";

export const FIVEABLE_UNIVERSE_GRADIENT = "linear-gradient(108.97deg, #019FB5 0%, #9864E7 94.53%)";

export const FIVEABLE_UNIVERSE_GRADIENT_DARK =
  "linear-gradient(109deg, rgba(1, 159, 181, 0.20) 0%, rgba(152, 100, 231, 0.20) 94.53%), #171717";

export const FIVEABLE_PASTEL_GRADIENT_DARK =
  "linear-gradient(186.94deg, rgba(204, 236, 240, 0.15) 10.62%, rgba(218, 209, 233, 0.15) 70.53%), #171717";

export const FIVEABLE_PASTEL_GRADIENT_SMALL_DARK =
  "linear-gradient(108.97deg, #3D2F5A 1.97%, #1E4A4D 94.53%)";

export const FIVEABLE_PASTEL_GRADIENT_DARK_LIGHT = `linear-gradient(109deg, rgba(225, 209, 250, 0.10) 1.97%, rgba(204, 236, 240, 0.10) 94.53%), #171717`;

export const FIVEABLE_CORRECT_GRADIENT = "linear-gradient(90deg, #33B469 0.07%, #019FB5 109.49%)";

export const FIVEABLE_INCORRECT_GRADIENT = "linear-gradient(90deg, #ED3A3A 0.07%, #EBBC2E 109.49%)";

export const FIVEABLE_SUNDAY_STUDY_GRADIENT =
  "linear-gradient(89.8deg, #019FB5 8.63%, #3976EF 107.05%), linear-gradient(0deg, #171717, #171717)";

const FADE_GRADIENT = "linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,.6) 100%)";

export const createDarkModePalette: ThemeExtender = (theme: Theme): Theme =>
  createTheme(theme, {
    palette: {
      mode: "dark",
      type: "dark",
      tonalOffset: 0.2,
      background: {
        fadeGradient: FADE_GRADIENT,
        paper: "#1E1E1E",
        default: "#1E1E1E",
        nav: "#1E1E1E",
        boxHighlight: theme.palette.grey[800],
        hover: {
          light: "#2C2C2C",
          reg: "#424242",
          dark: "#616161",
        },
        elevation: [
          "#1E1E1E",
          "#252525",
          "#2C2C2C",
          "#333333",
          "#3A3A3A",
          "#414141",
          "#484848",
          "#4F4F4F",
          "#565656",
          "#5D5D5D",
        ],
      },
      contrastThreshold: 3,
      text: {
        primary: "#DEDEDE",
        secondary: "#999999",
        disabled: "#616161",
      },
      divider: "#3A3A3A",
      common: {
        black,
        white,
      },
      gradients: {
        fiveableUniverse: FIVEABLE_UNIVERSE_GRADIENT,
        fiveableUniverseDark: FIVEABLE_UNIVERSE_GRADIENT_DARK,
        pastelGradient: FIVEABLE_PASTEL_GRADIENT_DARK,
        pastelGradientSmall: FIVEABLE_PASTEL_GRADIENT_SMALL_DARK,
        pastelGradientDark: FIVEABLE_PASTEL_GRADIENT_DARK_LIGHT,
        pastelGradientLight: FIVEABLE_PASTEL_GRADIENT_DARK_LIGHT,
        correctGradient: FIVEABLE_CORRECT_GRADIENT,
        incorrectGradient: FIVEABLE_INCORRECT_GRADIENT,
        fiveableSundayStudy: FIVEABLE_SUNDAY_STUDY_GRADIENT,
        gradientBackground: FIVEABLE_UNIVERSE_GRADIENT_DARK,
      },
      action: {
        active: "#67C6D3",
        hover: "#1E4A4D",
        selected: "#2B6B70",
        disabledBackground: "#333333",
        focus: "#1A5C62",
        disabled: "#6F6F6F",
      },
      primary: {
        main: "#4DBDCC",
        dark: "#01707F",
        light: "#A3E4EC",
        contrastText: "#171717",
        hover: "#5FA3AC",
        selected: "#6DB6BF",
        focus: "#5A989F",
        focusVisible: "#5FA3AC",
        outlineBorder: "#7ECED9",
      },
      secondary: {
        main: "#B08BED",
        dark: "#6D45A8",
        light: "#C6AEF2",
        contrastText: "#171717",
      },
      error: {
        main: "#F65555",
        dark: "#DB3034",
        light: "#FF7A7A",
        contrastText: "#FFFFFF",
      },
      success: {
        main: "#33B469",
        dark: "#1A8147",
        light: "#3D694F",
        contrastText: "#FFFFFF",
      },
      warning: {
        light: "#4D4230",
      },
    },
  });
