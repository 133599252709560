import { ComponentProvider } from "../util";

export const MuiAvatar: ComponentProvider = (theme) => ({
  MuiAvatar: {
    defaultProps: {
      variant: "circular",
    },
    styleOverrides: {
      circular: {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.common.black,
        color: "#FFF",
      },
    },
  },
});
