/* eslint-disable @next/next/no-sync-scripts */

import { useEffect, useState } from "react";
import { useSelector, Provider } from "react-redux";
import Head from "next/head";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { SnackbarProvider } from "notistack";
import { config } from "@fortawesome/fontawesome-svg-core";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useAccountGate from "@/hooks/Library/useAccountGate";
import { CacheProvider } from "@emotion/react";
import { useLegacyStore } from "@/legacy-store";
import { ClientUpdateRequiredModal } from "@/components/Modals/ClientUpdateRequiredModal";
import { legacyStoreContext, modernStoreContext } from "@/store/hooks";
import { wrapper } from "@/store";
import { IS_CRAWLER } from "@/utils/constants";
import { useTrackEvent } from "@/services";
import libraryLight, { libraryDark } from "@/styles/libraryThemeRefresh/libraryTheme";
import { saveLastSubjectLS } from "@/utils/Library/saveLastSubjectLS";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { useHiddenDivForGSC } from "@/utils/useHiddenDivForGSC";
import useGSCLoaded from "@/utils/useGSCLoaded";
import createEmotionCache from "../styles/createEmotionCache";
import useProfileService from "../services/useProfileService";
import "../styles/main.scss";
// eslint-disable-next-line import/no-unresolved
import "swiper/scss";
// eslint-disable-next-line import/no-unresolved
import "swiper/scss/navigation";
import "react-toggle/style.css";
import "emoji-mart/css/emoji-mart.css";
import "intro.js/introjs.css";
import "intro.js/themes/introjs-modern.css";
import "react-resizable/css/styles.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-calendar-heatmap/dist/styles.css";
import consoleEasterEgg from "../utils/consoleEasterEgg";
import meta from "../meta";

// Import the legacy CSS from Library
import "../css/index.css";

config.autoAddCss = false;

const AuthModals = dynamic(() => import("../components/Modals/Auth/AuthModals"), { ssr: false });

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

if (typeof window !== "undefined") {
  consoleEasterEgg();
}

/**
 * This separate `Main` component has access to the redux store,
 * since it's wrapped in the redux Provider component.
 * This allows us to call `getProfile`.
 */
function Main({ Component, pageProps }) {
  const { getProfile } = useProfileService();
  const router = useRouter();

  const libraryThemeMode = useSelector((state) => state.libraryThemeMode);
  const libraryTheme = libraryThemeMode === "dark" ? libraryDark : libraryLight;

  // ACCOUNT GATE EXPERIMENT
  const profileLoading = useSelector((state) => state.isProfileLoading);
  const accountGate = useAccountGate();
  const { trackEvent } = useTrackEvent();

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Update favicon
    const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "icon";
    link.href = "/favicon.ico?new";
    document.getElementsByTagName("head")[0].appendChild(link);
  }, [router]);

  useEffect(() => {
    if (profileLoading === false) {
      accountGate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.asPath, profileLoading]);

  useEffect(() => {
    getProfile();
    setIsClient(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const trackPageView = (url) => {
      return trackEvent({
        action: "VIEW PAGE",
        other: {
          sourceUrl: url,
        },
      });
    };

    if (router.isReady) {
      trackPageView(router.asPath);
    }
    router.events.on("routeChangeComplete", trackPageView);

    return () => {
      router.events.off("routeChangeComplete", trackPageView);
    };

    // by the time router is ready, all the redux state should be loaded and trackEvent should be reliable
    // need to change based on subject id so that generationMetadata is accurate
  }, [router.isReady]);

  // save last viewed subject so we can reference whether purchases were made from AP subjects or not
  useEffect(() => {
    const saveLastViewedSubject = () => {
      return saveLastSubjectLS({ pageProps, url: router?.asPath });
    };

    if (router.isReady) {
      saveLastViewedSubject();

      router.events.on("routeChangeComplete", saveLastViewedSubject);

      return () => {
        router.events.off("routeChangeComplete", saveLastViewedSubject);
      };
    }
    return undefined;
    // we'll do this for both initial load and subsequent navigations
    // by including router?.asPath in the dependency array, we ensure that the effect runs again if the route changes but the component doesn't remount
  }, [router.isReady, pageProps, router?.asPath]);

  return (
    <ThemeProvider theme={libraryTheme}>
      <CssBaseline />
      <Component {...pageProps} />
      <ClientUpdateRequiredModal />
      <AuthModals />
      {isClient && <SpeedInsights />}
    </ThemeProvider>
  );
}

const Scripts = () => {
  return (
    <>
      {/* these scripts should all be included in <head/> */}
      <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
      <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js" />
      <script src="/files/hotjar.js" />
      {meta.production && (
        <>
          {/* eslint-disable-next-line @next/next/next-script-for-ga */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-339Q8FL54M" />
          <script src="/files/ga4.js" />
        </>
      )}
    </>
  );
};

const getGscUrl = () => {
  if (typeof window === "undefined") return null;

  const { hostname } = window.location;
  if (hostname.includes("dev") || hostname.includes("localhost")) {
    // NOTE: this won't work for dev rooms, only library and localhost
    return "//l.getsitecontrol.com/xwke8v3w.js";
  }
  if (hostname === process.env.NEXT_PUBLIC_LIBRARY_BASE_URL?.replace("https://", "")) {
    return "//l.getsitecontrol.com/e4zjomd7.js";
  }
  return "//l.getsitecontrol.com/k4yo55j7.js";
};

const HiddenDivForGSC = () => {
  useHiddenDivForGSC();
  return null;
};

const setupGSCListeners = () => {
  const activeListeners = new Map();

  const showHandler = (widgetId) => {
    const closeHandler = () => {
      window.gsc("close", widgetId);
    };
    activeListeners.set(widgetId, closeHandler);
    document.addEventListener("click", closeHandler);
  };

  window.gsc("onShow", showHandler);

  return () => {
    activeListeners.forEach((handler) => {
      document.removeEventListener("click", handler);
    });
  };
};

const AppWithProviders = ({ Component, emotionCache = clientSideEmotionCache, ...rest }) => {
  const [gscUrl, setGscUrl] = useState(null);
  const isGSCLoaded = useGSCLoaded();
  const { store: modernStore, props } = wrapper.useWrappedStore(rest);
  const legacyStore = useLegacyStore(props.pageProps.initialReduxState);
  const router = useRouter();

  useEffect(() => {
    if (isGSCLoaded) {
      const cleanup = setupGSCListeners();
      return () => {
        if (cleanup) {
          cleanup();
        }
      };
    }
    return undefined; // Explicitly return undefined when isGSCLoaded is false
  }, [isGSCLoaded]);

  useEffect(() => {
    if (router.isReady) {
      setGscUrl(getGscUrl());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta name="google-site-verification" content="0YptATvm18pHvlI3S0ktS_Kn1AIdgyNANjwAv3_cgJA" />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link sizes="180x180" href="/apple-touch-icon.png" />

        <Scripts />
        {/* this doesn't properly render as part of the Scripts component, for whatever reason */}
        {!IS_CRAWLER && gscUrl && <script id="gsc-script" type="text/javascript" async src={gscUrl} />}
      </Head>
      <CacheProvider value={emotionCache}>
        <Provider store={legacyStore} context={legacyStoreContext}>
          <Provider store={modernStore} context={modernStoreContext}>
            <SnackbarProvider
              variant="info"
              hideIconVariant={false}
              preventDuplicate
              disableWindowBlurListener
              autoHideDuration={3000}
              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
              <Main Component={Component} pageProps={props.pageProps} />
              <HiddenDivForGSC />
            </SnackbarProvider>
          </Provider>
        </Provider>
      </CacheProvider>
    </>
  );
};

export default AppWithProviders;
