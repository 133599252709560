import { OptionsObject, useSnackbar } from "notistack";
import * as Sentry from "@sentry/nextjs";
import { ITrackEvent } from "@hours/utilities";
import debug from "debug";
import { Logger } from "@/utils/logger";
import useTrackEvent from "../../services/useTrackEvent";

const LOGID = "fiveable:frontend:hooks:utilities:useErrorHandler";
const dbg = debug(LOGID);

export type ErrorHandlerFunction = (err: Error, userMessage?: string) => void;

/**
 * This is a simple hook that can be used to display an error returned from the
 * apiClient, regardless of whether it returns an error object, or a plain error
 * string - it should generally be used whenever a snackbar needs to be shown,
 * and/or when an error should be logged to sentry and/or amplitude.
 *
 * @param showToUser Should errors handled by this errorHandler be shown to the user (via a
 * snackbar)?
 * @param sendToSentry Should errors handled by this errorHandler be sent to sentry?
 * @param sendToAmplitude Should errors handled by this errorHandler be sent to amplitude?
 *  This can be an ITrackEvent object to provide information about what the particular error
 *  being handled is to amplitude.
 * @param logId If you want pino logging to BetterStack
 *  FOR FUTURE ENG: we need to define the pino logger in this file, instead of passing it in,
 *  to avoid a 'this' error from occuring and then pino throws and error
 */
function useErrorHandler({
  showToUser = true,
  sendToSentry = false,
  sendToAmplitude = true,
  logId,
}: {
  showToUser?: boolean;
  sendToSentry?: boolean;
  sendToAmplitude?: boolean | ITrackEvent;
  logId?: string;
}): { handleError: ErrorHandlerFunction } {
  const loggerFn = logId ? Logger(logId) : null;
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = useTrackEvent();

  const handleError = (err: Error, userMessage?: string): void => {
    const errorMessage = userMessage ?? err.message;

    dbg(
      `Handling error. showToUser: ${showToUser}  sendToSentry: ${sendToSentry}  sendToAmplitude: ${sendToAmplitude}`
    );

    if (sendToSentry) {
      dbg("Sending to sentry.");
      Sentry.captureException(err);
    }

    if (loggerFn) {
      loggerFn.error(err);
    }

    if (sendToAmplitude) {
      let baseEvent: ITrackEvent = { category: "error", action: "unknown", label: "error-handled" };

      if (typeof sendToAmplitude !== "boolean") {
        baseEvent = sendToAmplitude;
      }
      trackEvent({ other: { errorMessage }, ...baseEvent });
    }

    if (showToUser) {
      const snackbarOptions: OptionsObject = {
        variant: "error",
      };

      enqueueSnackbar(errorMessage, snackbarOptions);
    }
  };

  return { handleError };
}

export default useErrorHandler;
