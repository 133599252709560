export const TESTIMONIALS: { id: string; text: string; attribution: string }[] = [
  {
    id: "test1",
    text: `"Fiveable helped me become SO much more confident, and I'm proud of myself!"`,
    attribution: "High School Sophomore, AP Chemistry Crams 2023",
  },
  {
    id: "test2",
    text: `“It honestly helped me out so much. I would’ve dropped a whole number score or two if I didn’t have the help from Fiveable.”`,
    attribution: "High School Freshman, AP HuG Crams 2023",
  },
  {
    id: "test3",
    text: `“Buy it, it's an investment for your score and your future.”`,
    attribution: "High School Junior, AP Chem Crams 2023",
  },
];
