import { ComponentProvider } from "../util";

export const MuiCard: ComponentProvider = (theme) => ({
  MuiCard: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        borderRadius: theme.borderRadii.LARGE,
        padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
        ...(["elevation", "outline"].includes(ownerState.variant) && {
          border: `1px solid ${theme.palette.common.black}`,
        }),
      }),
    },
  },
});
