import { ComponentProvider } from "../util";

export const MuiRating: ComponentProvider = (theme) => ({
  MuiRating: {
    styleOverrides: {
      root: {
        // TODO: theme composition
        "& .MuiRating-iconFilled": {
          color: theme.palette.primary.main,
        },
        "& .MuiRating-iconHover": {
          color: theme.palette.primary.light,
        },
      },
    },
  },
});
