import { useMemo } from "react";
import { ISubjectAutocompleteSubject, MailerLiteSubscriptor } from "@/types";
import { Profile } from "@/store/api/graphql/generated/types";
import useApiClient from "./useApiClient";
import { useAuthModals } from "../hooks/modals/useAuthModals";

export type MailerLiteService = {
  saveSubscriptor: (
    profile: Partial<Profile>,
    cta: string,
    subjects: Array<ISubjectAutocompleteSubject>,
    source: "library" | "rooms" | null,
    hsGraduationYear: string | null,
    level: string

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any>;
  updateSubscriptor: (
    email: string,
    fields: {
      [key: string]: unknown;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any>;
};

const parseName = (input: string): { firstName: string; lastName: string } | null => {
  if (typeof input === "string") {
    const [firstName, lastName] = input.split(" ");
    return { firstName, lastName };
  }

  return null;
};

const profileToSubscriptor = (profile: Partial<Profile>, source?: string | null): MailerLiteSubscriptor => {
  const parsed = parseName(profile.name as string);
  const joined =
    profile.joined instanceof Date
      ? profile.joined.toISOString()
      : profile.joined || new Date().toISOString();

  return {
    email: profile.email as string,
    fields: {
      firstName: parsed?.firstName || "",
      lastName: parsed?.lastName || "",
      libraryAccountCreated: source === "library",
      roomsAccountCreated: source === "rooms",
      updateEmailNotificationsOn: profile?.updateEmailNotificationsOn,
      subscribedDate: joined.split("T")[0],
    },
  };
};

function useMailerLiteService(): MailerLiteService {
  const { showLoginModal } = useAuthModals();
  const { apiClient } = useApiClient(showLoginModal);

  return useMemo(
    () => ({
      saveSubscriptor: async (profile, cta, subjects, source, hsGraduationYear, level) => {
        try {
          const response = await apiClient.post("/mailerlite/subscriptor", {
            subscriptor: profileToSubscriptor(profile, source),
            cta,
            subjects,
            hsGraduationYear,
            level,
          });
          return { subscriptorId: response.data?.subscriptorId };
        } catch (error) {
          return { subscriptorId: null };
        }
      },
      updateSubscriptor: async (email, fields) => {
        try {
          const response = await apiClient.post("/mailerlite/updateSubscriptor", {
            email,
            fields,
          });
          return { response };
        } catch (error) {
          return { error };
        }
      },
    }),
    [apiClient]
  );
}

export default useMailerLiteService;
