import { RootState } from "@/store";
import { IProfileBrowser } from "@hours/utilities";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthenticationState {
  isAuthenticated: boolean;
  profile?: IProfileBrowser;
}

const initialAuthenticationState: AuthenticationState = {
  isAuthenticated: false,
  profile: null,
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: initialAuthenticationState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload || false;
    },
    setProfile: (state, action: PayloadAction<IProfileBrowser>) => {
      state.profile = action.payload;
    },
    logOut: (state) => {
      state.isAuthenticated = false;
      state.profile = undefined;
    },
  },
});

export const { setIsAuthenticated, setProfile, logOut } = authenticationSlice.actions;

export const selectIsAuthenticated = (state: RootState): boolean => state.authentication.isAuthenticated;
export const selectProfile = (state: RootState): IProfileBrowser | undefined => state.authentication.profile;

export default authenticationSlice.reducer;
