import { ITrackEvent } from "@hours/utilities";
import { PageDataState, selectPageData } from "@/store/slices/PageDataSlice";
import { useModernStore } from "@/store/hooks";
import { trackEventJS } from "../utils/analytics";
import { getSubjectIsBeyondAp } from "../utils/Library/saveLastSubjectLS";

export const formatPageDataStateForTracking = (pageData: PageDataState): Record<string, unknown> => {
  const trackingData: Record<string, unknown> = {};
  if (pageData.subject) {
    trackingData.subjectName = pageData.subject.name;
    trackingData.subjectId = pageData.subject.id;
    trackingData.isBeyondAp = getSubjectIsBeyondAp(pageData.subject.name);
    trackingData.generationMetadata = pageData?.subject?.generationMetadata;
  } else {
    trackingData.isBeyondAp = null; // explicitly set this to null to indicate not subject scoped event
  }
  if (pageData.unit) {
    trackingData.unitName = pageData.unit.name;
    trackingData.unitId = pageData.unit.id;
  }
  if (pageData.topic) {
    trackingData.topicName = pageData.topic.name;
    trackingData.topicId = pageData.topic.id;
  }
  if (pageData.content) {
    trackingData.contentTitle = pageData.content.title;
    trackingData.contentId = pageData.content.id;
  }

  return trackingData;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useTrackEvent() {
  const store = useModernStore();

  // NOTE: because of Next SSR, unless you're calling this during a specific
  // user action (ex: onClick), you will probably want to wrap this in a
  // useEffect to prevent it from running server-side via SSR
  const trackEvent = (trackedEvent: ITrackEvent) => {
    const { category = "", action = "", label = "", nonInteraction = false, other = {} } = trackedEvent;
    const otherProperties = other;

    const pageDataSlice = selectPageData(store.getState());
    const pageDataProperties = formatPageDataStateForTracking(pageDataSlice);

    trackEventJS({
      category,
      action,
      label,
      nonInteraction,
      other: { ...pageDataProperties, ...otherProperties },
    });
  };

  return { trackEvent };
}
