/* eslint-disable max-lines-per-function */
import { ComponentProvider } from "../util";

export const MuiBadge: ComponentProvider = (theme) => ({
  MuiBadge: {
    styleOverrides: {
      colorPrimary: {
        background: theme.palette.gradients.fiveableUniverse,
      },
    },
  },
});
