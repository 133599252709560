import { ComponentProvider } from "../util";

export const MuiAutocomplete: ComponentProvider = (theme) => ({
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        "& .MuiAutocomplete-clearIndicator": {
          padding: 0,
        },
        "& .MuiAutocomplete-popupIndicator": {
          padding: 0,
        },
      },
      paper: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "14px",
        boxShadow: "0px 2px 0px 0px #000",
        overflowY: "auto",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        fontColor: `${theme.palette.common.black}`,
      },
      // TODO: Confirm hover/focused/select states with design team.
      // The color on hover is e4f3f6 which comes from the common.action theme rule defined in this file
      option: {
        '&[aria-selected="true"]': {
          backgroundColor: "transparent",
        },
        '&.Mui-focused[aria-selected="true"]': {
          backgroundColor: "transparent",
        },
      },
    },
  },
});
