import * as Sentry from "@sentry/nextjs";
import { IProfileBrowser } from "@hours/utilities";
import { Context } from "@sentry/types";

export const setSentryUser = (
  profile: IProfileBrowser | null,
  other: { [key: string]: string | number } = {}
): void => {
  const isServer = typeof window === "undefined";
  if (isServer) return;

  Sentry.setUser(profile ? { id: profile._id, email: profile.email, ...other } : null);
};

export const getSentryEnvironmentClient = (): string => {
  const env = "local";
  return process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || env;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sendSentryException(err: Error, options: Record<string, any> = {}): void {
  Sentry.withScope(async (scope) => {
    if (options?.tags) {
      scope.setTags(options?.tags);
    }

    if (options?.context) {
      scope.setContext("exception context", options?.context);
    }

    Sentry.captureException(err);
    await Sentry.flush(2000);
  });
}

export function setSentryContext(name: string, context: Context): void {
  Sentry.setContext(name, context);
}
