import { useDispatch, useSelector } from "react-redux";
import { setIsAuthenticated } from "@/store/auth";
import { useModernDispatch } from "@/store/hooks";
import { IProfileBrowser } from "@hours/utilities";
import { setAnalyticsUser } from "./analytics";
import { setSentryUser } from "./sentry";
import logoutCleanup from "./logoutCleanup";

// custom react hook to load profile, or set loading state
// used in navbars and other first-load items so this needs to be clean

// isProfileLoading will always be a not-null boolean (true, false), whereas profile can
// be null (when loading and when not logged in)

interface UseProfileReturnType {
  profile: IProfileBrowser | null;
  isProfileLoading?: boolean;
  isLoggedIn: boolean;
  isLoggedOut: boolean;
}

export default function useProfile(): UseProfileReturnType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isProfileLoading = useSelector((state) => (state as any).isProfileLoading);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const profile = useSelector((state) => (state as any).profile as IProfileBrowser | null);

  return {
    profile,

    // We are attempting to fetch the profile data
    isProfileLoading: typeof isProfileLoading === "boolean" ? isProfileLoading : true,

    // We have successfully fetched the profile data
    isLoggedIn: !isProfileLoading && Boolean(profile?._id),

    // We have tried to fetch the data, but the user isn't logged in
    isLoggedOut: !isProfileLoading && !profile,
  };
}

interface UseProfileActionsReturnType {
  removeProfileAction: () => void;
  setProfileLoadingAction: (isLoading?: boolean) => void;
  updateProfileAction: (profile: IProfileBrowser | null) => void;
}

export function useProfileActions(): UseProfileActionsReturnType {
  const dispatch = useDispatch();
  const modernDispatch = useModernDispatch();

  const updateProfileAction = (profile) => {
    dispatch({ type: "UPDATE_PROFILE", payload: profile });
    if (profile) {
      modernDispatch(setIsAuthenticated(true));
    }
    setAnalyticsUser(profile);
    setSentryUser(profile);
  };

  const removeProfileAction = () => {
    dispatch({ type: "UPDATE_PROFILE", payload: null });
    logoutCleanup();
    modernDispatch(setIsAuthenticated(false));
    setAnalyticsUser(null);
    setSentryUser(null);
  };

  const setProfileLoadingAction = (isLoading = false) => {
    dispatch({ type: "UPDATE_PROFILE_LOADING", payload: isLoading });
  };

  return { removeProfileAction, setProfileLoadingAction, updateProfileAction };
}
