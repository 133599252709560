export const imgPath404 = "img/ogimages/invalid-session.png";

export const IS_CRAWLER =
  typeof window !== "undefined" &&
  window.navigator &&
  /bot|googlebot|crawler|spider|robot|crawling/i.test(
    window && window.navigator && window.navigator.userAgent
  );

export const timerSounds = [
  "Station",
  "Spell",
  "Woohoo",
  "Tones",
  "Warp",
  "Piano",
  "Classical",
  "Doorbell",
] as const;

// name of the branch we are slotting user requested subjects into
export const STUDENT_REQ_BRANCH = "branch";

export const accessTypeDescriptions = {
  open: "Open sessions can be joined by anyone",
  locked: "Locked sessions can only be joined using the invite link",
};

export const accessTypeAlerts = {
  open: "Session is now open, anyone with the link can join",
  locked: "Session is now locked, ask the host for the new session link for access",
};

export const libraryLogo5Darkmode = "/img/logos/library_5_logo_darkmode.png";
export const libraryLogo5Lightmode = "/img/logos/library_5_logo_lightmode.png";
export const libraryLogoLightmode = "/img/logos/library_logo_lightmode.png";
export const libraryLogoDarkmode = "/img/logos/library_logo_darkmode.png";
export const FIVEABLE_LOGO_LIGHTMODE_CRAM_SEASON = "/img/logos/fiveable_logo_lightmode_cram_season.svg";
export const FIVEABLE_LOGO_5_LIGHTMODE_CRAM_SEASON = "/img/logos/fiveable_logo_5_lightmode_cram_season.svg";
export const FIVEABLE_LOGO_DARKMODE_CRAM_SEASON = "/img/logos/fiveable_logo_darkmode_cram_season.svg";
export const FIVEABLE_LOGO_5_DARKMODE_CRAM_SEASON = "/img/logos/fiveable_logo_5_darkmode_cram_season.svg";

export const CRAM_SEASON_LOGOS = {
  light: FIVEABLE_LOGO_LIGHTMODE_CRAM_SEASON,
  dark: FIVEABLE_LOGO_DARKMODE_CRAM_SEASON,
  light5: FIVEABLE_LOGO_5_LIGHTMODE_CRAM_SEASON,
  dark5: FIVEABLE_LOGO_5_DARKMODE_CRAM_SEASON,
};

export const LIBRARY_LOGOS = {
  light: libraryLogoLightmode,
  dark: libraryLogoDarkmode,
  light5: libraryLogo5Lightmode,
  dark5: libraryLogo5Darkmode,
};

export const IS_CRAM_SEASON = process.env.NEXT_PUBLIC_IS_CRAM_SEASON === "true";

// not technically a constant but..... close enough?
export const fiveableLogos = IS_CRAM_SEASON ? CRAM_SEASON_LOGOS : LIBRARY_LOGOS;
