import { useMemo } from "react";
import useApiClient from "./useApiClient";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function usePMFSurveyService() {
  const { apiClient } = useApiClient();

  return useMemo(
    () => ({
      async shouldShowPMF(id: string) {
        return (
          await apiClient.get("/profile/should-show-pmf/", {
            params: { id },
            metadata: { handleErrors: true },
          })
        )?.data as { shouldShowPMF: boolean };
      },
      async completedPMF(id: string) {
        return (await apiClient.post("/profile/completed-pmf", { id }, { metadata: { handleErrors: true } }))
          ?.data as void;
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
