import { Theme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import { MuiAccordion } from "./MuiAccordion";
import { MuiAppBar } from "./MuiAppBar";
import { MuiButton } from "./MuiButton";
import { MuiAutocomplete } from "./MuiAutocomplete";
import { MuiCheckbox } from "./MuiCheckbox";
import { MuiChip } from "./MuiChip";
import { MuiTextField } from "./MuiTextField";
import { MuiIconButton } from "./MuiIconButton";
import { MuiInputBase } from "./MuiInputBase";
import { MuiInputLabel } from "./MuiInputLabel";
import { MuiOutlinedInput } from "./MuiOutlinedInput";
import { MuiRating } from "./MuiRating";
import { MuiAvatar } from "./MuiAvatar";
import { MuiCard } from "./MuiCard";
import { MuiSwitch } from "./MuiSwitch";
import { MuiSelect } from "./MuiSelect";
import { MuiBadge } from "./MuiBadge";
import { MuiDivider } from "./MuiDivider";
/**
 * "registers" the components in the array by deep merging them into the theme,
 * after passing theme values into them component definition.
 * @see ComponentProvider
 */
export const componentsToRegister = [
  MuiAccordion,
  MuiButton,
  MuiAppBar,
  MuiAutocomplete,
  MuiCheckbox,
  MuiChip,
  MuiTextField,
  MuiIconButton,
  MuiInputBase,
  MuiInputLabel,
  MuiOutlinedInput,
  MuiRating,
  MuiAvatar,
  MuiCard,
  MuiSwitch,
  MuiSelect,
  MuiBadge,
  MuiDivider,
].map(
  (component) =>
    (theme: Theme): Theme =>
      deepmerge(theme, {
        components: component(theme),
      })
);
