import { Action, configureStore, ThunkAction, combineReducers } from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

// this makes it possible to call dispatch via useModernDispatch for graphql calls without TS errors.
// there was a github issue, but I lost it
import type {} from "redux-thunk/extend-redux";
import "reflect-metadata";

/* eslint-disable import/no-cycle */
import meta from "@/meta";
import authenticationReducer from "./auth";
import graphqlApi from "./api/graphql/api";
import tocReducer from "./slices/TableOfContentsSlice";
import pageDataSlice from "./slices/PageDataSlice";
import storage from "./storage";
/* eslint-enable import/no-cycle */

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["tracking"],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    [graphqlApi.reducerPath]: graphqlApi.reducer,
    authentication: authenticationReducer,
    tableOfContents: tocReducer,
    pageData: pageDataSlice,
  })
);

const getReducer = (usePersist: boolean) =>
  usePersist
    ? persistedReducer
    : {
        [graphqlApi.reducerPath]: graphqlApi.reducer,
        authentication: authenticationReducer,
        tableOfContents: tocReducer,
        pageData: pageDataSlice,
      };

const getMiddleware = (usePersist: boolean) =>
  usePersist
    ? {
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }
    : undefined;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeStore = (devTools = { name: "pages modern store" }, usePersist = false) =>
  configureStore({
    reducer: getReducer(usePersist),
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware(getMiddleware(usePersist)).concat(graphqlApi.middleware);
    },
    // don't enable devtools in production
    devTools: meta.isDev ? devTools : false,
  });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
