import { ComponentProvider } from "../util";

export const MuiOutlinedInput: ComponentProvider = () => ({
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: "24px",
      },
      sizeSmall: { padding: "8px 12px" },
      input: {
        padding: 0,
      },
      inputSizeSmall: {
        padding: 0,
      },
    },
  },
});
