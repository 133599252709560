import { useDispatch, useSelector } from "react-redux";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRefreshModals = () => {
  const refreshModal = useSelector((state: { refreshModal: boolean }) => state.refreshModal);
  const dispatch = useDispatch();

  const hideRefreshModal = () => {
    if (refreshModal) {
      dispatch({ type: "HIDE_REFRESH_MODAL" });
    }
  };

  const showRefreshModal = () => {
    dispatch({ type: "SHOW_REFRESH_MODAL" });
  };

  return { hideRefreshModal, showRefreshModal };
};
