import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMoon, faMusic, faUser, faUsers } from "@fortawesome/pro-solid-svg-icons";
import chat from "../../public/img/homepage/chat.png";
import tasks from "../../public/img/homepage/tasks.png";
import timer from "../../public/img/homepage/timer.png";

export const NAV_HEIGHT = "76px";
export const SIX_HOURS_IN_SECONDS = 6 * 60 * 60;
export const CATEGORY_ORDER = [
  "College Admissions",
  "Arts",
  "Capstone",
  "English",
  "History",
  "Science",
  "Math & Computer Science",
  "Social Science",
  "World Languages & Cultures",
  "General",
];

export const BROWSE_RESOURCES_CATEGORY_ORDER = [
  "Math & Computer Science",
  "Science",
  "History",
  "Social Science",
  "World Languages & Cultures",
  "English",
  "Arts",
  "Capstone",
  "College Admissions",
  "General",
];

export const CATEGORY_OPTIONS = ["New Subject", ...CATEGORY_ORDER];

export const CATEGORY_TAGS = {
  "New Subject": "NEW",
};

export interface FeatureData {
  id: number;
  featureName: string;
  icon: IconProp;
  title: string;
  subtitle: string;
}

export const featureData: FeatureData[] = [
  {
    id: 1,
    featureName: "friends",
    icon: faUsers,
    title: "Friends",
    subtitle: "Add your friends and see their statuses, all from your dashboard.",
  },
  {
    id: 2,
    featureName: "darkmode",
    icon: faMoon,
    title: "Dark Mode",
    subtitle: "Relax your eyes with dark mode, perfect for late night studying.",
  },
  {
    id: 3,
    featureName: "lofi",
    icon: faMusic,
    title: "Lofi music",
    subtitle: "Listen to our lofi playlist, with 100+ handpicked songs from our team.",
  },
  {
    id: 4,
    featureName: "solomode",
    icon: faUser,
    title: "Solo mode",
    subtitle: "Like working solo? Change up the interface to zero in on your timer and tasks.",
  },
];

interface FocusStepData {
  id: number;
  title: string;
  subtitle: string;
  img: string;
  imgAlt?: string;
}
export const focusStepData: FocusStepData[] = [
  {
    id: 1,
    title: "Start your session",
    subtitle: "Start your session in 10 seconds with no sign-up required.",
    img: "/img/illustrations/start.svg",
    imgAlt: "person clicking on giant web browser",
  },
  {
    id: 2,
    title: "Invite your friends",
    subtitle: "Invite your friends with a custom session invite link.",
    img: "/img/illustrations/invite.svg",
    imgAlt: "letter with wings flying out of mailbox",
  },
  {
    id: 3,
    title: "Get to work!",
    subtitle: "Add your tasks, set the timer, and get some work done.",
    img: "/img/illustrations/coworking.svg",
    imgAlt: "person with long hair working on their laptop at a cafe",
  },
];

export const swiperBreakpoints = {
  100: {
    slidesPerView: 1,
  },
  600: {
    slidesPerView: 2,
  },
  700: {
    slidesPerView: 3,
  },
  900: {
    slidesPerView: 4,
  },
  1200: {
    slidesPerView: 5,
  },
};

interface HomepageAboutData {
  id: number;
  title: string;
  subtitle: string;
  imageURL: string;
}

export const homepageAboutData: HomepageAboutData[] = [
  {
    id: 1,
    title: "Collab with friends",
    subtitle:
      "Use group chat to plan a session or just hang out. Quickly invite anyone with a shareable link.",
    imageURL: chat?.src,
  },
  {
    id: 2,
    title: "Focus with timers",
    subtitle: "Set a group timer for everyone to work together, or a personal timer that fits your own flow.",
    imageURL: timer?.src,
  },
  {
    id: 3,
    title: "Showcase your goals",
    subtitle:
      "Easily manage your to-dos with our simple task list and display your progress to everyone in the room.",
    imageURL: tasks?.src,
  },
];

interface EngagementCardData {
  id: number;
  label: string;
  maxNum: number;
  suffix: string;
}

export const engagementCardData: EngagementCardData[] = [
  {
    id: 1,
    label: "students",
    maxNum: 65,
    suffix: "k",
  },
  {
    id: 2,
    label: "study sessions",
    maxNum: 200,
    suffix: "k",
  },
  {
    id: 3,
    label: "tasks completed",
    maxNum: 1,
    suffix: "m",
  },
  {
    id: 4,
    label: "hours of studying",
    maxNum: 250,
    suffix: "k",
  },
];
