import { Theme, ThemeOptions } from "@mui/material/styles";

export type ComponentProvider = (theme?: Theme) => ThemeOptions["components"];

/**
 * utility type for the below function. basically this is a function
 * that calls createTheme or deepmerge (or both) on the theme and things you want to augment it with.
 * for example, adding component overrides or color palettes.'
 */
export type ThemeExtender = (theme: Theme) => Theme;

/**
 * @param theme a base mui theme object for other theme objects to augment and extend.
 * @param extender a function that takes a theme and returns an extended theme.
 * practically, this is a function that merges some theme property, like a palette, into the theme.
 * this compositional pattern allows us to be self-referential in our theme - we can use theme colors in
 * custom component variants, for example.
 * @returns a new mui theme, extended by the extender.
 */
export const extendTheme = (theme: Theme, extender: ThemeExtender): Theme => extender(theme);

/**
 * merges some number of new mui themes into each other. see above.
 * @param baseTheme a base mui theme
 * @param themes augmentary themes that may be dependent on previous themes in the list
 * @returns a complete theme
 */
export const composeThemes = (baseTheme: Theme, themes: ThemeExtender[]): Theme =>
  themes.reduce(extendTheme, baseTheme);
