import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import debug from "debug";
import { useMemo } from "react";
import axiosBetterStacktrace from "axios-better-stacktrace";
import meta from "../meta";
import { trackEventJS } from "../utils/analytics";
import { useCheckClientVersion } from "../hooks/utilities/useCheckClientVersion";
import { useRefreshModals } from "../hooks/modals/useRefreshModals";
import useErrorHandler from "../hooks/utilities/useErrorHandler";

const dbg = debug("fiveable:frontend:hooks:useApiClient");
const defaultLogId = "fiveable:frontend:hooks:useApiClient";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApiError = any;
export type ErrorHandler = boolean | string | ((res: AxiosError) => void);

// Extend the Axios types to allow us to attach a metadata object to the config
declare module "axios" {
  export interface AxiosRequestConfig {
    metadata?: {
      handleErrors?: ErrorHandler;
      skipErrorReporting?: (error: ApiError) => boolean;
    };
  }
}

export type ApiClientHook = {
  apiClient: {
    get: AxiosInstance["get"];
    post: AxiosInstance["post"];
    put: AxiosInstance["put"];
    patch: AxiosInstance["patch"];
    delete: AxiosInstance["delete"];
  };
};

const defaultAxiosOptions = {
  baseURL: meta.backendUrl,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

export const axiosInstance = axios.create(defaultAxiosOptions);
// This module helps provide better stack trace info from axios.  See
// https://github.com/axios/axios/issues/2387 for more information.
if (process.env.NODE_ENV !== "test") axiosBetterStacktrace(axiosInstance);
export const isForbiddenError = (error: ApiError): boolean => error?.response?.status === 403;
export const isUnauthorizedError = (error: ApiError): boolean => error?.response?.status === 401;

function useApiErrorHandler(showLoginModal: () => void) {
  const { handleError } = useErrorHandler({ sendToAmplitude: false, sendToSentry: false });

  const apiErrorHandler = (error: ApiError, handler: ErrorHandler): void => {
    // If the handler passed in is a function, call it with the error
    // and return.
    if (typeof handler === "function") {
      handler(error);
      return;
    }

    // If the error is an auth error, display the login modal.
    const isUnauthorized = isUnauthorizedError(error);
    if (isUnauthorized) showLoginModal();

    // If the error handler is a string, return that string, otherwise
    // return a generic error.
    const msg = typeof handler === "string" ? handler : "Something went wrong - please try again!";

    // Call the global error handler with the error and the message.
    handleError(error, `${isUnauthorized ? "Session Error" : "Error"}: ${msg}`);
  };

  return { errorHandler: apiErrorHandler };
}

// Intercept errors from all requests for some common error handling
// This can't use hooks to prevent the error from being sent multiple times
axiosInstance.interceptors.response.use(
  (res) => res, // pass successful responses through without change
  (error) => {
    const skipErrorReporting = error?.config?.metadata?.skipErrorReporting?.(error) || false;

    if (!skipErrorReporting) {
      if (isForbiddenError(error)) {
        const request = `${error?.config?.method} ${error?.config?.url}`;
        trackEventJS({ action: "403 error", other: { request } });
      }

      if (isUnauthorizedError(error)) {
        const request = `${error?.config?.method} ${error?.config?.url}`;
        trackEventJS({ action: "401 error", other: { request } });
      }
    }

    return Promise.reject(error);
  }
);

/**
 * This is a simple hook that can be used to display an error returned from the
 * apiClient, regardless of whether it returns an error object, or a plain error
 * string - it should generally be used whenever a snackbar needs to be shown.
 * @param sendToSentry boolean to be passed to useErrorHandler (see useErrorHandler for info)
 * @param logId string to be passed to useErrorHandler (see useErrorHandler for info)
 * @param err The AxiosError returned from the apiClient.
 */
function useShowApiError({
  sendToSentry = false,
  logId = defaultLogId,
}: {
  sendToSentry?: boolean;
  logId?: string;
}): {
  showApiError: (err: AxiosError) => void;
} {
  // useErrorHandler defaults sendToSentry as being true, but as of 1/4/24 we've designated sentry for major exceptions only (not expected, everyday API errors), and so we're adding the option to disable it here.
  // What we should be doing instead is sending this data to betterstack, which we are doing in some cases, but here we can simply pass in the desired logging function to have useErrorHandler take care of this as well.
  const { handleError } = useErrorHandler({ sendToAmplitude: false, sendToSentry, logId });

  const showApiError = (err: AxiosError): void => {
    const message = typeof err?.response?.data === "string" ? err.response.data : err.response.data.message;

    handleError(err, message);
  };

  return { showApiError };
}

// eslint-disable-next-line max-lines-per-function
function useApiClient(showLoginModal: () => void): ApiClientHook {
  const { isAllowedClientVersion } = useCheckClientVersion();
  const { showRefreshModal } = useRefreshModals();
  const { errorHandler } = useApiErrorHandler(showLoginModal);

  axiosInstance.interceptors.response.use((res: AxiosResponse): AxiosResponse => {
    const serverRequirement = res.headers["x-fiveable-client-requirements"];
    dbg("Server requirement for clients is: ", serverRequirement);
    if (!serverRequirement || isAllowedClientVersion(serverRequirement)) {
      dbg("Requirement met.");
      return res;
    }

    // Only show the refresh modal if we've made it down to here.
    showRefreshModal();
    return res;
  });

  return useMemo(() => {
    return {
      apiClient: {
        get: async (...props) => {
          try {
            return await axiosInstance.get(...props);
          } catch (e) {
            const handleErrors = props[1]?.metadata?.handleErrors;
            if (handleErrors) errorHandler(e, handleErrors);
            throw e;
          }
        },
        post: async (...props) => {
          try {
            return await axiosInstance.post(...props);
          } catch (e) {
            const handleErrors = props[2]?.metadata?.handleErrors;
            if (handleErrors) errorHandler(e, handleErrors);
            throw e;
          }
        },
        put: async (...props) => {
          try {
            return await axiosInstance.put(...props);
          } catch (e) {
            const handleErrors = props[2]?.metadata?.handleErrors;
            if (handleErrors) errorHandler(e, handleErrors);
            throw e;
          }
        },
        patch: async (...props) => {
          try {
            return await axiosInstance.patch(...props);
          } catch (e) {
            const handleErrors = props[2]?.metadata?.handleErrors;
            if (handleErrors) errorHandler(e, handleErrors);
            throw e;
          }
        },
        delete: async (...props) => {
          try {
            return await axiosInstance.delete(...props);
          } catch (e) {
            const handleErrors = props[1]?.metadata?.handleErrors;
            if (handleErrors) errorHandler(e, handleErrors);
            throw e;
          }
        },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export { useShowApiError };
export default useApiClient;
