import { ComponentProvider } from "../util";

export const MuiInputBase: ComponentProvider = () => ({
  MuiInputBase: {
    styleOverrides: {
      root: {
        padding: "16px 12px",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0.15px",
      },
      inputSizeSmall: {
        padding: 0,
      },
    },
  },
});
