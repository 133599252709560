import { ComponentProvider } from "../util";

export const MuiButton: ComponentProvider = (theme) => ({
  MuiButton: {
    defaultProps: {
      disableFocusRipple: true,
      disableRipple: true,
      disableTouchRipple: true,
      sx: {
        boxShadow: 0,
        "&:hover": {
          boxShadow: 0,
        },
      },
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        minWidth: "unset!important",
        WebkitUserSelect: "text",
        MozUserSelect: "text",
        msUserSelect: "text",
        userSelect: "text",

        ...(ownerState.disabled && {
          filter: "greyscale(100%) !important",
          opacity: 0.5,
          background:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, 0.12) !important"
              : "rgba(0, 0, 0, 0.12) !important",
        }),

        ...(ownerState.size === "large" && {
          fontSize: theme.typography.pxToRem(12),
          ...(["text", "textPrimary", "textSecondary", "textHighlighted"].includes(ownerState.variant) && {
            fontSize: theme.typography.pxToRem(16),
            [theme.breakpoints.down("sm")]: {
              fontSize: theme.typography.pxToRem(14),
            },
          }),
        }),
        ...(ownerState.size === "medium" && {
          fontSize: theme.typography.pxToRem(10),
        }),
        ...(ownerState.size === "small" && {
          fontSize: theme.typography.pxToRem(8),
        }),

        ...(["contained", "primary", "secondary", "error"].includes(ownerState.variant) && {
          border: theme.palette.mode === "dark" ? `1px solid ${theme.palette.grey[500]}` : "1px solid black",
          borderRadius: theme.borderRadii.LARGE,
          textTransform: "none",
          boxShadow: `${theme.shadows[1]} !important`,
          ...(ownerState.size === "large" && {
            padding: "12px 20px",
          }),
          ...(ownerState.size === "medium" && {
            padding: "8px 12px",
          }),
          ...(ownerState.size === "small" && {
            padding: "4px 8px",
            minWidth: "unset",
          }),
          "&:hover": {
            boxShadow: `${theme.shadows[4]} !important`,
            color:
              theme.palette.mode === "dark"
                ? theme.palette.secondary.main
                : theme.palette.secondary.contrastText,
          },
        }),
      }),

      containedSuccess: {
        "&.AnswerButton.Mui-disabled": {
          backgroundColor: `${theme.palette.success.main}!important`,
          color: theme.palette.mode === "dark" ? "#171717" : "#fff",
          opacity: 1,
        },
      },
      containedError: {
        "&.AnswerButton.Mui-disabled": {
          backgroundColor: `${theme.palette.error.main}!important`,
          color: theme.palette.mode === "dark" ? "#171717" : "#fff",
          opacity: 1,
        },
      },

      primary: {
        "&.AnswerButton.Mui-disabled": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? `${theme.palette.grey[600]}!important`
              : `${theme.palette.common.white}!important`,
          color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
          opacity: 1,
        },
      },
    },

    variants: [
      {
        props: { variant: "primary" },
        style: {
          backgroundColor: theme.palette.primary.main,
          color: "white",
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "white",
          },
        },
      },
      {
        props: { variant: "secondary" },
        style: {
          background: theme.palette.gradients.fiveableUniverse,
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.common.white,
          "&:hover": {
            background: theme.palette.secondary.dark,
            color: theme.palette.common.white,
          },
        },
      },
      {
        props: { variant: "contained" },
        style: {
          backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[600] : theme.palette.common.white,
          color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
          "&:hover": {
            backgroundColor:
              theme.palette.mode === "dark" ? theme.palette.grey[500] : theme.palette.primary.dark,
            color: theme.palette.common.white,
          },
        },
      },
      {
        props: { variant: "error" },
        style: {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.mode === "dark" ? "#171717" : "white",
          "&:hover": {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.mode === "dark" ? "#171717" : "white",
          },
        },
      },
      {
        props: { variant: "textPrimary" },
        style: {
          color: theme.palette.primary.light,
          display: "inline-flex",
          padding: 0,
          textDecoration: "underline",
          textTransform: "none",

          "&:hover": {
            background: "none",
            color: theme.palette.primary.dark,
            textDecoration: "underline",
          },
        },
      },
      {
        props: { variant: "textSecondary" },
        style: {
          color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.text.primary,
          display: "inline-flex",
          fontWeight: 400,
          padding: 0,
          textTransform: "none",

          "&:hover": {
            background: "none",
            color: theme.palette.primary.light,
          },
        },
      },
      {
        props: { variant: "textHighlighted" },
        style: {
          background: theme.palette.primary.focus,
          borderRadius: theme.borderRadii.XS,
          color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.text.primary,
          display: "inline-flex",
          padding: 0,
          textTransform: "none",
          verticalAlign: "baseline",

          "&:hover": {
            background: theme.palette.primary.focusVisible,
          },
        },
      },
      {
        props: { variant: "text" },
        style: {
          color: theme.palette.mode === "dark" ? theme.palette.grey[300] : theme.palette.text.secondary,
          display: "inline-flex",
          padding: 0,
          textTransform: "none",

          "&:hover": {
            textDecoration: "none",
            background: "none!important",
            backgroundColor: "none!important",
          },
        },
      },
    ],
  },
});
