import { trackEventJS } from "./analytics";

/**
 * Handles general cleanup when logging a user out
 */
export default function logoutCleanup() {
  // Track event
  trackEventJS({ action: "logout" });

  // Remove localstorage related to the account
  if (typeof window !== "undefined" && window?.localStorage) {
    Object.keys(localStorage).forEach((key) => {
      if (
        key.endsWith("·participant") ||
        key.endsWith("·intro-start") ||
        key.endsWith("·prompt-signup") ||
        key.endsWith("·intro-invite") ||
        key.endsWith("·prompt-invite") ||
        key.endsWith("·participant-id")
      ) {
        localStorage.removeItem(key);
      }
    });

    localStorage.removeItem("theme");
    localStorage.removeItem("profile");
  }
}
