import { createTheme, Theme } from "@mui/material/styles";
import { ThemeExtender } from "../util";

export const white = "#ffffff";
export const black = "#171717";

export const FIVEABLE_UNIVERSE_GRADIENT = "linear-gradient(108.97deg, #019FB5 0%, #9864E7 94.53%)";

export const FIVEABLE_UNIVERSE_GRADIENT_LIGHT =
  "linear-gradient(109deg, rgba(1, 159, 181, 0.10) 0%, rgba(152, 100, 231, 0.10) 94.53%), #FFFFFF";

export const FIVEABLE_PASTEL_GRADIENT =
  "linear-gradient(186.94deg, rgba(204, 236, 240, 0.4) 10.62%, rgba(218, 209, 233, 0.4) 70.53%), #FFFFFF";

export const FIVEABLE_PASTEL_GRADIENT_SMALL = "linear-gradient(108.97deg, #E1D1FA 1.97%, #CCECF0 94.53%)";

export const FIVEABLE_PASTEL_GRADIENT_LIGHT = `linear-gradient(109deg, rgba(225, 209, 250, 0.20) 1.97%, rgba(204, 236, 240, 0.20) 94.53%), #FFF`;

export const FIVEABLE_CORRECT_GRADIENT = "linear-gradient(90deg, #33B469 0.07%, #019FB5 109.49%)";

export const FIVEABLE_INCORRECT_GRADIENT = "linear-gradient(90deg, #ED3A3A 0.07%, #EBBC2E 109.49%)";

export const FIVEABLE_SUNDAY_STUDY_GRADIENT =
  "linear-gradient(89.8deg, #019FB5 8.63%, #3976EF 107.05%), linear-gradient(0deg, #171717, #171717)";

const FADE_GRADIENT = "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,.6) 100%)";

/**
 * merges a color palette into the mui theme. gives access to other theme variables while merging.
 * @param theme a mui theme
 * @returns a mui theme that has a new palette
 */

/* eslint-disable-next-line max-lines-per-function */
export const createLightModePalette: ThemeExtender = (theme: Theme): Theme =>
  createTheme(theme, {
    palette: {
      mode: "light",
      type: "light",
      tonalOffset: 0.2,
      background: {
        fadeGradient: FADE_GRADIENT,
        paper: white,
        default: white,
        nav: white,
        pure: white,
        boxHighlight: "#CCECF0",
        hover: {
          light: "#f5f5f5",
          reg: "#9e9e9e",
          dark: "#212121",
        },
        elevation: [white, white, white, white, white, white, white, white, white, white],
      },
      contrastThreshold: 3,
      text: {
        primary: "#171717",
        secondary: "#747474",
        disabled: "#A7A7A7",
      },
      divider: "#E3E3E3",
      common: {
        black,
        white,
      },
      gradients: {
        fiveableUniverse: FIVEABLE_UNIVERSE_GRADIENT,
        fiveableUniverseLight: FIVEABLE_UNIVERSE_GRADIENT_LIGHT,
        pastelGradient: FIVEABLE_PASTEL_GRADIENT,
        pastelGradientSmall: FIVEABLE_PASTEL_GRADIENT_SMALL,
        pastelGradientLight: FIVEABLE_PASTEL_GRADIENT_LIGHT,
        correctGradient: FIVEABLE_CORRECT_GRADIENT,
        incorrectGradient: FIVEABLE_INCORRECT_GRADIENT,
        fiveableSundayStudy: FIVEABLE_SUNDAY_STUDY_GRADIENT,
        gradientBackground: FIVEABLE_UNIVERSE_GRADIENT_LIGHT,
      },
      action: {
        active: "#80CFDA",
        hover: "#D8F0F4",
        selected: "#94D6E0",
        disabledBackground: "#E3E3E3",
        focus: "#B4E3E9",
        disabled: "#A7A7A7",
      },
      primary: {
        main: "#67C6D3",
        dark: "#018092",
        light: "#1AAABD",
        contrastText: "#171717",
        hover: "#D8F0F4",
        selected: "#94D6E0",
        focus: "#B4E3E9",
        focusVisible: "#D8F0F4",
        outlineBorder: "#7ECED9",
      },
      secondary: {
        main: "#9864E7",
        dark: "#6D45A8",
        light: "#6D45A8",
        contrastText: "#171717",
      },
      error: {
        main: "#DB3034",
        dark: "#CE282D",
        light: "#F65555",
        contrastText: "#FFFFFF",
      },
      success: {
        main: "#1A8147",
        dark: "#0C6133",
        light: "#C0D8C1",
        contrastText: "#FFFFFF",
      },
      warning: {
        light: "#F9EBC0",
      },
    },
  });
