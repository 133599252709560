import { useState, useEffect } from "react";

const useGSCLoaded = (): boolean => {
  const [isGSCLoaded, setIsGSCLoaded] = useState(false);

  useEffect(() => {
    const checkGSCLoaded = () => {
      if (window.gsc) {
        setIsGSCLoaded(true);
      }
    };

    const intervalId = setInterval(checkGSCLoaded, 100);

    return () => clearInterval(intervalId);
  }, []);

  return isGSCLoaded;
};

export default useGSCLoaded;
