import { ComponentProvider } from "../util";

export const MuiDivider: ComponentProvider = (theme) => ({
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: theme.palette.mode === "dark" ? theme.palette.text.primary : "rgba(23, 23, 23, 0.12)",
      },
    },
  },
});
