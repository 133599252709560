export const profileMenuLinks = [
  {
    href: "/dashboard/settings/profile",
    emoji: "🧑",
    text: "Profile",
    value: "Settings",
    dataTestId: "profile-settings-link",
  },
  {
    href: "/dashboard/crams",
    emoji: "🌶️",
    text: "Crams HQ",
    value: "Crams HQ",
    dataTestId: "crams-dashboard",
  },
  {
    href: "/dashboard",
    emoji: "✅",
    text: "Rooms HQ",
    value: "Rooms HQ",
    dataTestId: "rooms-dashboard",
  },

  {
    href: "/logout",
    emoji: "🚪",
    text: "Log out",
    value: "Log out",
    dataTestId: "log-out",
  },
];

export const cramHostProfileMenuLink = {
  href: "/cram-quiz/host",
  emoji: "✏️",
  text: "Cram Quizzes",
  value: "Crams Quiz Library",
  dataTestId: "crams-quiz-library",
};

export const adminProfileMenuLink = {
  href: "/admin-panel",
  emoji: "👤",
  text: "Admin Panel",
  value: "Admin Panel",
  dataTestId: "admin-panel-library",
};
