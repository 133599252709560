import { IProfileBrowser } from "@hours/utilities";

// Utility for setting user properties in Amplitude.
// Input: { 2223: ["sub1", "sub2"], 2324: [ "sub3", "sub4"] }
// Output: [ "2223_sub1", "2223_sub2", "2324_sub3", "2324_sub4" ]
export const formatSubjectsAndExams = (profile: IProfileBrowser): string[] => {
  if (!profile || !profile.subjectsAndExams) {
    return [];
  }

  return Object.entries(profile.subjectsAndExams).reduce((acc, [year, subjects]) => {
    const prefixedSubjects = subjects.map((subject) => `${year}_${subject}`);
    return [...acc, ...prefixedSubjects];
  }, []);
};
