import { useMemo } from "react";
import { IProfileBrowser } from "@hours/utilities";
import useApiClient from "./useApiClient";

export type AdminService = {
  updateSubjectCheatsheets: (subjects: { [subjectId: string]: boolean }) => Promise<{ result: unknown }>;
  getProfiles: (email: string) => Promise<Partial<IProfileBrowser>[]>;
  updateProfile: (profile: Partial<IProfileBrowser>) => Promise<Partial<IProfileBrowser>>;
};

export function useAdminService(): AdminService {
  const { apiClient } = useApiClient();

  return useMemo(
    () => ({
      updateSubjectCheatsheets: async (subjects: {
        [subjectId: string]: boolean;
      }): Promise<{ result: unknown }> => {
        try {
          return (
            await apiClient.put("/update-subject-cheatsheets", {
              subjects,
              metadata: { handleErrors: `Error updating subject cheatsheets` },
            })
          )?.data;
        } catch (e) {
          return null;
        }
      },
      getProfiles: async (email: string): Promise<Partial<IProfileBrowser>[]> => {
        try {
          return (
            await apiClient.get("/profile/user", {
              params: { email },
              metadata: { handleErrors: `Error retrieving profile for ${email} - does the user exist?` },
            })
          )?.data;
        } catch (e) {
          return null;
        }
      },
      updateProfile: async (profile): Promise<Partial<IProfileBrowser>> => {
        try {
          return (
            await apiClient.post(
              "/profile/user",
              { ...profile },
              {
                metadata: { handleErrors: `Error updating profile for ${profile.email}` },
              }
            )
          )?.data;
        } catch {
          return null;
        }
      },
    }),
    []
  );
}
