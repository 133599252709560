import { ComponentProvider } from "../util";

export const MuiInputLabel: ComponentProvider = () => ({
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "12px",
        letterSpacing: "0.15px",
        color: "rgba(23, 23, 23, 0.6)",
      },
      outlined: {
        top: "5px",
      },
      filled: {},
    },
  },
});
