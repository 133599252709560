/* eslint-disable no-console */

const blue = "#01a0b6";

const header = `%c

      555555555    5555555                                                         5555555            5555555                 
     55555555555   5555555                                                         5555555            5555555                 
     5555555                                                                       5555555            5555555                 
   5555555555555   5555555  555555      555555   5555555555         55555555555    55555555555555     5555555     5555555555
   5555555555555   5555555   555555    555555  55555555555555      55555555555555  5555555555555555   5555555   55555555555555
     5555555       5555555    555555  555555  555555   5555555            5555555  5555555  55555555  5555555  555555   5555555
     5555555       5555555     555555555555   5555555555555555     55555555555555  5555555   5555555  5555555  5555555555555555
     5555555       5555555      5555555555    5555555555555555  55555555555555555  5555555   5555555  5555555  5555555555555555
     5555555       5555555       55555555     555555    555555  55555555  5555555  5555555  5555555   5555555  555555    555555 
     5555555       5555555        555555       55555555555555    5555555555555555  5555555555555555   5555555   55555555555555
     5555555       5555555         5555          5555555555       555555555555555  55555555555555     5555555     5555555555
  
  `;

const headerStyles = `
  font-family: monospace;
  font-weight: bold;
  font-size: 7px;
  color: ${blue};
  text-shadow: 3px 0px 0px rgba(0, 0, 0, 0.9),
    3px 3px 0px rgba(0, 0, 0, 0.8),
    0px 3px 0px rgba(0, 0, 0, 0.7),
    -3px 3px 0px rgba(0, 0, 0, 0.6),
    -3px 0px 0px rgba(0, 0, 0, 0.5),
    -3px -3px 0px rgba(0, 0, 0, 0.4),
    0px -3px 0px rgba(0, 0, 0, 0.3),
    3px -3px 0px rgba(0, 0, 0, 0.2);
`;

const welcome = `%c \n      Welcome to the Fiveable console!\n\n`;

const welcomeStyles = `
  font-family: monospace;
  font-weight: bold;
  color: ${blue};
  font-size: 20px;
`;

const text = `%cIf you're here because an error occurred, we're sorry to hear that! Reach out to help@fiveable.me for support.

Just looking around? We're happy to have you here! (p.s.: feel free to check out our job board at https://fiveable.me/about/jobs — we're big fans of curious people like you!)`;

const textStyles = `
  font-weight: bold;
  font-size: 13px;
`;

const warning = `%cBy the way, make sure to never paste anything into this console since it can lead to your account getting hacked! 😱`;

const warningStyles = `
  font-weight: bold;
  color: red;
`;

export default function consoleEasterEgg(): void {
  if (process.env.NODE_ENV === "development") return;

  console.log(header, headerStyles);
  console.log(welcome, welcomeStyles);
  console.log(text, textStyles);
  console.log(warning, warningStyles);
}
