/* eslint-disable max-lines-per-function */
import { ComponentProvider } from "../util";

export const MuiAccordion: ComponentProvider = () => ({
  MuiAccordion: {
    defaultProps: {
      elevation: 0,
      sx: {
        boxShadow: 0,
      },
    },
    styleOverrides: {
      root: () => ({
        backgroundColor: "inherit",

        "&.MuiPaper-root:before": {
          backgroundColor: "none",
          background: "none",
        },

        "&.Mui-expanded": {
          margin: 0,
        },
      }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: 0,
        minHeight: 0,
        height: "auto",

        "&.Mui-expanded": {
          minHeight: 0,
          height: "auto",
        },
      },
      content: {
        margin: "0 !important",
      },
      contentGutters: {
        margin: "0 !important",
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: "8px 0",
      },
    },
  },
});
