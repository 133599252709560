import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { GraphQLClient } from "graphql-request";
import NodeCache from "node-cache";

import { buildCreateApi, coreModule, reactHooksModule } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";

import { HYDRATE } from "next-redux-wrapper";
import meta from "@/meta";
import { useModernDispatch, useModernSelector, useModernStore } from "@/store/hooks";

const apiCache = new NodeCache();

const customCreateApi = buildCreateApi(
  coreModule(),
  reactHooksModule({
    useDispatch: useModernDispatch,
    useSelector: useModernSelector,
    useStore: useModernStore,
  })
);

const GraphQLEndpoint = `${meta.backendUrl}/graphql`;
const baseQueryFunction = graphqlRequestBaseQuery({
  url: GraphQLEndpoint,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  client: new GraphQLClient(GraphQLEndpoint, { credentials: "include" }),
});

const api = customCreateApi({
  reducerPath: "api",
  // TODO: figure out why this data is not being refetched properly
  keepUnusedDataFor: 21600,
  // eslint-disable-next-line consistent-return
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  baseQuery: (args, ap, extraOptions): MaybePromise<QueryReturnValue<unknown, unknown, {}>> => {
    const cacheKey = `${ap.type}-${ap.endpoint}-${Object.values(args.variables).join("-")}`;

    if (process.env.NEXT_PHASE === "phase-production-build") {
      const cached = apiCache.get(cacheKey);
      if (cached) {
        return cached as QueryReturnValue;
      }
    }

    const res = baseQueryFunction(args, ap, extraOptions);
    if (process.env.NEXT_PHASE === "phase-production-build") {
      apiCache.set(cacheKey, res);
    }
    return res;
  },
  /* baseQuery: graphqlRequestBaseQuery({
    url: GraphQLEndpoint,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    client: new GraphQLClient(GraphQLEndpoint, { credentials: "include" }),
  }),
  */
  endpoints: () => ({}),
});

export { api };

export default api;
