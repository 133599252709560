/* eslint-disable max-lines */
import { PracticeQuestionDifficulty } from "../../store/api/graphql/generated/types";

export const LIMIT_OPTIONS = [
  { label: "5", value: 5, id: "5", key: "5" },
  { label: "10", value: 10, id: "10", key: "10" },
  { label: "15", value: 15, id: "15", key: "15" },
  { label: "20", value: 20, id: "20", key: "20" },
];

export const LIMIT_DEFAULT = LIMIT_OPTIONS[0];

export enum DifficultyColors {
  EASY = "#A5D38D",
  MEDIUM = "#FFDA7A",
  HARD = "#FFA77A",
  EXTREMELY_HARD = "#DABDFF",
}

export enum DifficultyIcons {
  EASY = "difficulty_easy_flame.svg",
  MEDIUM = "difficulty_medium_flame.svg",
  HARD = "difficulty_hard_flame.svg",
  EXTREMELY_HARD = "difficulty_extremely_hard_flame.svg",
}

export enum PqDifficulty {
  EASY = "EASY",
  MEDIUM = "MEDIUM",
  HARD = "HARD",
  EXTREMELY_HARD = "EXTREMELY_HARD",
}
export enum PqDifficultyLabels {
  EASY = "Easy",
  MEDIUM = "Medium",
  HARD = "Hard",
  EXTREMELY_HARD = "Extremely Hard",
}

export const DIFFICULTY_OPTIONS = [
  {
    label: PqDifficultyLabels.EASY,
    value: PracticeQuestionDifficulty.Easy,
    id: PracticeQuestionDifficulty.Easy,
    key: PracticeQuestionDifficulty.Easy,
    backgroundColor: DifficultyColors.EASY,
    icon: DifficultyIcons.EASY,
  },
  {
    label: PqDifficultyLabels.MEDIUM,
    value: PracticeQuestionDifficulty.Medium,
    id: PracticeQuestionDifficulty.Medium,
    key: PracticeQuestionDifficulty.Medium,
    backgroundColor: DifficultyColors.MEDIUM,
    icon: DifficultyIcons.MEDIUM,
  },
  {
    label: PqDifficultyLabels.HARD,
    value: PracticeQuestionDifficulty.Hard,
    id: PracticeQuestionDifficulty.Hard,
    key: PracticeQuestionDifficulty.Hard,
    backgroundColor: DifficultyColors.HARD,
    icon: DifficultyIcons.HARD,
  },
  {
    label: PqDifficultyLabels.EXTREMELY_HARD,
    value: PracticeQuestionDifficulty.ExtremelyHard,
    id: PracticeQuestionDifficulty.ExtremelyHard,
    key: PracticeQuestionDifficulty.ExtremelyHard,
    backgroundColor: DifficultyColors.EXTREMELY_HARD,
    icon: DifficultyIcons.EXTREMELY_HARD,
  },
];

export const UNIT_DEFAULT = { id: "all", label: "All units", slug: "all", name: "all" };
export const TOPIC_DEFAULT = { id: "all", label: "All topics", slug: "all", name: "all" };
export const DIFFICULTY_DEFAULT = DIFFICULTY_OPTIONS[0];

export const SUBJECT_SLUGS_WITH_ONLY_EASY_QUESTIONS = ["act", "sat"];

export const SUBJECT_DEFAULT = {
  id: "ap-world-history",
  name: "AP World History: Modern",
  label: "🌍 AP World History: Modern",
  emoji: "🌍",
  slug: "ap-world",
  units: [
    {
      id: "Oaxh4M7J5jo9jTj9",
      name: "Unit 1 – The Global Tapestry, 1200-1450",
      slug: "unit-1",
      topics: [
        {
          id: "ZUzXORUfN4DATRzOs60dR",
          name: "1.1 East Asia from 1200-1450",
        },
        {
          id: "IlGsWUN0yKnVDEWM-3pCl",
          name: "1.2 Dar al-Islam from 1200-1450",
        },
        {
          id: "DHYm6ku6eoSx9n8nZwe5j",
          name: "1.3 South and Southeast Asia from 1200-1450",
        },
        {
          id: "LdhN_5OVIiIl_Z-Sdw47u",
          name: "1.4 The Americas from 1200 to 1450",
        },
        {
          id: "S4r10rKbDpvgsMtgIhTTY",
          name: "1.5 Africa from 1200 to 1450",
        },
        {
          id: "xx5IlwJUEa84YHU5y_Ini",
          name: "1.6 Europe from 1200 to 1450",
        },
        {
          id: "7cyUNkwtN9axHYiHeuAJN",
          name: "1.7 Comparisons in the Period from 1200-1450",
        },
      ],
    },
    {
      id: "Tia5ZYr8MtCN2Ekl",
      name: "Unit 2 – Networks of Exchange, 1200-1450",
      slug: "unit-2",
      topics: [
        {
          id: "dvuvA216RkTFdnApAX3b6",
          name: "2.1 Silk Roads",
        },
        {
          id: "wOxClgpiCzoo04rUwMLMG",
          name: "2.2 The Mongol Empire and the Making\nof the Modern World",
        },
        {
          id: "sWiwY0vDt6mD0t9a4fVO1",
          name: "2.3 Indian Ocean Trade Routes",
        },
        {
          id: "kmk__QK22M7Gbf8SunA2r",
          name: "2.4 Trans-Saharan Trade Routes",
        },
        {
          id: "MViyW03DuSHDigEqPcplc",
          name: "2.5 Cultural Effects of Trade ",
        },
        {
          id: "D0DhedWP4BkDtCTCCbK_8",
          name: "2.6 Environmental Effects of Trade",
        },
        {
          id: "-adW1b9WEH8rvASYozmJ_",
          name: "2.7 Comparison in Trade from 1200-1450",
        },
      ],
    },
    {
      id: "v3AYcZV6ntPu4g8o",
      name: "Unit 3 – Land-Based Empires, 1450-1750",
      slug: "unit-3",
      topics: [
        {
          id: "V6R-5adc2V2mbrL3rh-Os",
          name: "3.1 Expansion of Land-Based Empires",
        },
        {
          id: "bNhimzXHj3b2n-UhA7joz",
          name: "3.2 Governments of Land-Based Empires",
        },
        {
          id: "7O2g8F_7PrSmoYCDNI9B0",
          name: "3.3 Belief Systems of Land-Based Empires",
        },
        {
          id: "Pm4RIYkYe8A1bUmeqLMlD",
          name: "3.4 Comparison in Land-Based Empires",
        },
      ],
    },
    {
      id: "E8B6Td8YoJ3IMt3G",
      name: "Unit 4 – Transoceanic Interactions, 1450-1750",
      slug: "unit-4",
      topics: [
        {
          id: "3qdJlQYn6nodZZZfzXpgp",
          name: "4.1 Technological Innovations from 1450 to 1750",
        },
        {
          id: "zCFGKjvXrlkHCT3njrw7m",
          name: "4.2 Exploration",
        },
        {
          id: "8S1h_nQO_1kK8R9HcGtAy",
          name: "4.3 Columbian Exchange",
        },
        {
          id: "La1Hdyuo5q-pCr-Dwt_Hz",
          name: "4.4 Maritime Empires Established",
        },
        {
          id: "QfgOPHM_54sle-EwyprmI",
          name: "4.5 Maritime Empires Maintained and Developed",
        },
        {
          id: "WRpw9tN1xf-wt7BYYg8Q-",
          name: "4.6 Resistance to European Expansion",
        },
        {
          id: "eODSXtcXVnl_HD2o_6nPJ",
          name: "4.7 Class and Race from 1450-1750",
        },
        {
          id: "kDk1Sf-L3UN49aDFoKULG",
          name: "4.8 Multiple Choice Questions",
        },
      ],
    },
    {
      id: "C01sxzzLG990jM7a",
      name: "Unit 5 – Revolutions, 1750-1900",
      slug: "unit-5",
      topics: [
        {
          id: "CHCyn9qds1iSApJevnvlN",
          name: "5.1 The Enlightenment",
        },
        {
          id: "AD9DWbhO8JTrfh7qDj5dB",
          name: "5.2 Nationalism and Revolutions from 1750-1900",
        },
        {
          id: "A6ndymugGquwcX14DAiQI",
          name: "5.3 Industrialization Begins",
        },
        {
          id: "0_yzPnKwBvtqkZBBWi0Oq",
          name: "5.4 Industrialization Spreads in the Period from 1750 to 1900",
        },
        {
          id: "bv8-QCLXBUS_31SB8Ghy_",
          name: "5.5 Technology in the Industrial Age",
        },
        {
          id: "I1hd4r045IzyPD6K297Gh",
          name: "5.6 State-Led Industrialization",
        },
        {
          id: "O5xJgMwZLjh-JJfLU9LkS",
          name: "5.7 Economic Effects of Industrialization",
        },
        {
          id: "n4uRfgg1EUZb00xBgyhSr",
          name: "5.8 Responses to Industrialization",
        },
        {
          id: "7zmx132tn_TB1cQ-zYSCC",
          name: "5.9 Society and the Industrial Age",
        },
        {
          id: "sgZtFqwk1pVMvc-v68WWH",
          name: "5.10 Continuity and Change in the Industrial Age",
        },
      ],
    },
    {
      id: "MBDZJpSeXDVlEkck",
      name: "Unit 6 – Consequences of Industrialization, 1750-1900",
      slug: "unit-6",
      topics: [
        {
          id: "_pAEWKGe6R899EfLWlweK",
          name: "6.1 Rationales for Imperialism",
        },
        {
          id: "QfrHw-DStR7ME4WBmLQEd",
          name: "6.2 Expansion of Imperialism",
        },
        {
          id: "izB5zp0B5vJqIQRxai8yC",
          name: "6.3 Indigenous Responses to Imperialism",
        },
        {
          id: "swgvwM7A9eowc1_m0FMQO",
          name: "6.4 Global Economic Development from 1750 to 1900",
        },
        {
          id: "7Wrs7PhJwgKBv4eRbr3S0",
          name: "6.5 Economic Imperialism",
        },
        {
          id: "_ibw8VEpWHCaxskoyfNTt",
          name: "6.6 Causes of Migration from 1750 to 1900",
        },
        {
          id: "dnjAJZ2bRts0IKqOX6JQb",
          name: "6.7 Effects of Migration from 1750 to 1900",
        },
        {
          id: "MZrOAbePpjmFGBO6oCKg4",
          name: "6.8 Causation in the Imperial Age",
        },
      ],
    },
    {
      id: "EZNML2TXU4RbMHLd",
      name: "Unit 7 – Global Conflict, 1900-Present",
      slug: "unit-7",
      topics: [
        {
          id: "KcbCAIdHI4FCAnaLVt0bu",
          name: "7.1 Shifting Power After 1900",
        },
        {
          id: "gM-wBjQs7dbCNhBzgKcJi",
          name: "7.2 Causes of World War I",
        },
        {
          id: "5TR24DkpqvpEFbte-u7gZ",
          name: "7.3 Conducting World War I",
        },
        {
          id: "QZURsbT5w3_JahoLh4jNF",
          name: "7.4 Economy in the Interwar Period",
        },
        {
          id: "O6o7KwhaFYA-nLTNvWwsQ",
          name: "7.5 Unresolved Tensions After World War I",
        },
        {
          id: "DnUKnb2ek4rGtRd47oHLw",
          name: "7.6 Causes of World War II",
        },
        {
          id: "sCcczzfMIZv5iLCqzmcEW",
          name: "7.7 Conducting World War II",
        },
        {
          id: "Uc8UMzOouplaDnUdC2CZ5",
          name: "7.8 Mass Atrocities After 1900",
        },
        {
          id: "9o3XGc9du0u4XmFV0nxmK",
          name: "7.9 Causation in Global Conflict",
        },
      ],
    },
    {
      id: "jSpra9uFr57Mpf1B",
      name: "Unit 8 – Cold War & Decolonization, 1900-Present",
      slug: "unit-8",
      topics: [
        {
          id: "Iu1g8P0i-bCKrF_D_DPtD",
          name: "8.1 Setting the Stage for the Cold War and Decolonization",
        },
        {
          id: "XjTBZ9woSVpzFwthW7MR6",
          name: "8.2 The Cold War",
        },
        {
          id: "v6dHWvPSf-Fe1YxkZdqYZ",
          name: "8.3 Effects of the Cold War",
        },
        {
          id: "DTeCRultDqHcGxf9BxEKA",
          name: "8.4 Spread of Communism After 1900",
        },
        {
          id: "omc3sYe7vlZP94E_5fPLO",
          name: "8.5 Decolonization After 1900",
        },
        {
          id: "H4aioGM4njlMKU_dnA1NS",
          name: "8.6 Newly Independent States After 1900",
        },
        {
          id: "4S6tJSi-38koBYtBp6BnJ",
          name: "8.7 Global Resistance in the 20th Century",
        },
        {
          id: "58aywhCQRGETgURFURO28",
          name: "8.8 End of the Cold War",
        },
        {
          id: "S8IGY2W619HlKAjyLoQ-t",
          name: "8.9 Causation in the Age of the Cold War and Decolonization",
        },
      ],
    },
    {
      id: "P6UjdjtfRd2nJ5P5",
      name: "Unit 9 – Globalization, 1900-Present",
      slug: "unit-9",
      topics: [
        {
          id: "JCAKWfBbr35h8LyaeaJJN",
          name: "9.1 Advances in Technology and Exchange after 1900",
        },
        {
          id: "XIR9cdC6jgY7WQzU6ukuN",
          name: "9.2 Technological Advances and Limitations after 1900",
        },
        {
          id: "1DtHJVt-_TAAFVZZZMIKK",
          name: "9.3 Technological Advances",
        },
        {
          id: "g8XfJWnMadRyVdnn_Wsbr",
          name: "9.4 Economics in the Global Age",
        },
        {
          id: "HmUdBA1mw9uhBmefQnmuB",
          name: "9.5 Calls for Reform and Responses after 1900",
        },
        {
          id: "PPLl_3z2mLb4D9A9BOZjC",
          name: "9.6 Globalized Culture after 1900",
        },
        {
          id: "QGwAmZVSyugdRY8IqKfsa",
          name: "9.7 Resistance to Globalization After 1900",
        },
        {
          id: "1J8H0KLVpJw5fi4nyT_X0",
          name: "9.8 Institutions Developing in a Globalized World",
        },
        {
          id: "U499wof4O7BiITPnP9C0n",
          name: "9.9 Continuity and Change in a Globalized World",
        },
      ],
    },
  ],
};
