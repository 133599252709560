import { ComponentProvider } from "../util";

export const MuiSelect: ComponentProvider = () => ({
  MuiSelect: {
    defaultProps: {
      size: "small",
      variant: "outlined",
    },
    styleOverrides: {
      outlined: {},
    },
  },
});
