import { ComponentProvider } from "../util";

export const MuiTextField: ComponentProvider = () => ({
  MuiTextField: {
    defaultProps: {
      size: "small",
    },
    styleOverrides: {},
  },
});
