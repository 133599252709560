import getConfig from "next/config";
import semver from "semver/preload";
import debug from "debug";

const dbg = debug("fiveable:frontend:hooks:useCheckClientVersion");

/**
 * This hook is used to determine if the client version meets the requirements of
 * the server version.  The `isAllowedClientVersion` function will return `true` if
 * the current client version meets the server's requirements, OR if the
 * ENABLE_CLIENT_VERSION_CHECKS environment variable is not set at build time.
 *
 * It tries to be _very_ cautious, and only return false if we're _really_ sure
 * that it's a mismatched version.  If there's any ambiguity or errors, we return
 * true.
 */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCheckClientVersion() {
  const isAllowedClientVersion = (serverRequirement: string) => {
    const { publicRuntimeConfig } = getConfig();
    // If ENABLE_CLIENT_VERSION_CHECKS is not enabled, these will not be set, so we just
    // return true, to say the client is OK!
    if (!publicRuntimeConfig?.clientBuildTimestamp && !publicRuntimeConfig?.clientBuildVersion) {
      return true;
    }

    dbg("Client Build Timestamp is: ", publicRuntimeConfig.clientBuildTimestamp);
    dbg("Client Build Version is: ", publicRuntimeConfig.clientBuildVersion);

    // Likewise, if the server did not send us a version requirement, we're gonna say
    // "sure, we'll try it".
    if (!serverRequirement) {
      return true;
    }

    // serverRequirement can be _either_ a semver requirement string, per
    // https://www.npmjs.com/package/semver, or it can be a simple "milliseconds
    // since epoch" JavaScript timestamp.  We'll use semver.valid to determine which
    // it is.
    const timestamp = parseInt(serverRequirement, 10);
    if (semver.valid(serverRequirement) || Number.isNaN(timestamp)) {
      dbg("Checking requirements as semver.");
      // Just make sure we have a clientBuildVersion, and fail open if not.
      if (!publicRuntimeConfig.clientBuildVersion) {
        return true;
      }
      // It's a semver, yay!
      dbg("Semver requirement is: ", serverRequirement);
      return semver.satisfies(publicRuntimeConfig.clientBuildVersion, serverRequirement);
    }

    // It's a timestamp. Yay?
    dbg("Checking requirements as timestamp.");
    try {
      // Just make sure we have a clientBuildTimestamp, and fail open if not.
      if (!publicRuntimeConfig.clientBuildTimestamp) {
        return true;
      }
      dbg("Timestamp requirement is: ", timestamp);

      // If the client's build timestamp is _after_ the server's requirement,
      // we're good!
      return publicRuntimeConfig.clientBuildTimestamp > timestamp;
    } catch (e) {
      // If parsing the timestamp fails, we're going to fail open and allow the client.
      return true;
    }
  };

  return {
    isAllowedClientVersion,
  };
}
