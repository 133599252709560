import { ComponentProvider } from "../util";

// eslint-disable-next-line max-lines-per-function
export const MuiSwitch: ComponentProvider = (theme) => ({
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: "unset",
        height: "unset",
      },
      switchBase: {
        top: "5px",
        left: "5px",
        "& .Mui-checked": {
          left: "3px",
        },
      },
      track: {
        opacity: 1,
        borderRadius: "100px",
        border: "1px solid #171717",
      },
      thumb: {
        // Default thumb styles
        border: "1px solid #171717",
        boxShadow: "none",
      },
      sizeSmall: {
        "& .MuiSwitch-switchBase": { left: "5px" },
        "& .MuiSwitch-thumb": {
          width: "8px",
          height: "8px",
        },
        "& .MuiSwitch-track": {
          width: "28px",
          height: "12px",
        },
      },
      sizeMedium: {
        "& .MuiSwitch-switchBase": {
          left: "5px",
          "&.Mui-checked": {
            left: "1px",
          },
        },
        "& .MuiSwitch-thumb": {
          width: "12px",
          height: "12px",
        },
        "& .MuiSwitch-track": {
          width: "32px",
          height: "16px",
        },
      },

      colorPrimary: {
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.primary.dark, // Default state for primary variant
        },
        "& .MuiSwitch-thumb": {
          backgroundColor: theme.palette.primary.main,
        },
        "&.Mui-checked": {
          "& .MuiSwitch-thumb": {
            backgroundColor: theme.palette.primary.dark, // Checked state track background
          },
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: theme.palette.primary.main, // Checked state for primary variant
          },
        },
        "&.Mui-disabled": {
          "& .MuiSwitch-thumb": {
            opacity: 1,
            backgroundColor: "#f5f5f5", // Disabled thumb color
            border: "1px solid #727272",
          },
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: "#e3e3e3", // Disabled track color
            border: "1px solid #727272",
          },
          "&.Mui-checked": {
            "& .MuiSwitch-thumb": {
              opacity: 1,
              backgroundColor: "#f5f5f5", // Disabled and checked thumb color
              border: "1px solid #727272",
            },
            "& + .MuiSwitch-track": {
              opacity: 1,
              backgroundColor: "#e3e3e3", // Disabled and checked track color
              border: "1px solid #727272",
            },
          },
        },
      },
    },
  },
});
