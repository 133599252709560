/* eslint-disable @typescript-eslint/explicit-module-boundary-types,max-lines */
import algoliasearch from "algoliasearch";
import { createNullCache } from "@algolia/cache-common";
import meta from "@/meta";

// eslint-disable-next-line import/no-cycle
// import { getHasuraLinkAndInfo, getResourceIcon } from "@/utils/Library/contentTypes";

import { Unit } from "@/store/api/graphql/generated/types";
import { toMilSecs } from "./dates";

export default function useScreenSize() {
  // TODO: broken
  return {
    isXsDown: false,
    isSmDown: false,
    isMdDown: false,
    isLgDown: true,
    // isXsDown: useMediaQuery(theme.breakpoints.down("xs")),
    // isSmDown: useMediaQuery(theme.breakpoints.down("sm")),
    // isMdDown: useMediaQuery(theme.breakpoints.down("md")),
    // isLgDown: useMediaQuery(theme.breakpoints.down("lg")),
  };
}

export const GenerateId = (num) => {
  const length = num || 16;
  const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const nums = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
  const smAlphabet = alphabet.map((l) => l.toLowerCase());
  const chars = alphabet.concat(nums, smAlphabet);

  let id = "";
  let i;
  for (i = 0; i < length; i++) {
    const letter = chars[Math.floor(Math.random() * chars.length)];
    id += letter;
  }
  return id;
};

export const generateCode = (num) => {
  return new Promise((resolve, reject) => {
    const length = num || 8;
    const alphabet = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    let code = "";
    let i;
    for (i = 0; i < length; i++) {
      const letter = alphabet[Math.floor(Math.random() * alphabet.length)];
      code += letter;
    }
    if (code.length > 1) {
      resolve(code);
    } else {
      reject(code);
    }
  });
};

export const generateToken = (num) => {
  return new Promise((resolve, reject) => {
    const length = num || 16;
    const alphabet = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    const nums = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    const smAlphabet = alphabet.map((l) => l.toLowerCase());
    const chars = alphabet.concat(nums, smAlphabet);

    let token = "";
    let i;
    for (i = 0; i < length; i++) {
      const letter = chars[Math.floor(Math.random() * chars.length)];
      token += letter;
    }
    if (token.length > 1) {
      resolve(token);
    } else {
      reject(token);
    }
  });
};

export const capitalizeName = (name) =>
  (name &&
    name
      .split(" ")
      .map((n) => (n[0] || "").toUpperCase() + n.slice(1))
      .join(" ")) ||
  "";

export const dynamicSort = (key) => {
  let sortOrder = 1;
  if (key[0] === "-") {
    sortOrder = -1;
    // eslint-disable-next-line no-param-reassign
    key = key.substr(1);
  }

  const dateTerms = [
    "date",
    "createdAt",
    "updatedAt",
    "sessionStart",
    "sessionEnd",
    "start",
    "end",
    "dueDate",
    "start_time",
    "lastTrendingDate",
  ];
  const date = dateTerms.indexOf(key) > -1;

  if (date) {
    return (a, b) => {
      const result =
        // eslint-disable-next-line no-nested-ternary
        toMilSecs(a[key]) < toMilSecs(b[key]) ? -1 : toMilSecs(a[key]) > toMilSecs(b[key]) ? 1 : 0;
      return result * sortOrder;
    };
  }
  return (a, b) => {
    // eslint-disable-next-line no-nested-ternary
    const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    return result * sortOrder;
  };
};

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export const shuffleArray = (array) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    // eslint-disable-next-line no-param-reassign
    array[currentIndex] = array[randomIndex];
    // eslint-disable-next-line no-param-reassign
    array[randomIndex] = temporaryValue;
  }

  return array;
};
export const areObjectsEquivalent = (a, b) => {
  const aProps = Object.getOwnPropertyNames(a).sort();
  const bProps = Object.getOwnPropertyNames(b).sort();

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  return true;
};

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const isEmailValid = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // Was previously: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isNotANumber = (value) => Number.isNaN(Number(value));

// https://stackoverflow.com/a/2686098/9263888
export function abbrNum(number, decPlaces) {
  // 2 decimal places => 100, 3 => 1000, etc
  const factor = 10 ** decPlaces;

  // Enumerate number abbreviations
  const abbrev = ["k", "m", "b", "t"];

  let result = number;

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = 10 ** ((i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= result) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      result = Math.round((result * factor) / size) / factor;

      // Handle special case where we round up to the next abbreviation
      if (result === 1000 && i < abbrev.length - 1) {
        result = 1;
        i += 1;
      }

      // Add the letter for the abbreviation
      result += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return number;
}

export const isValidUrl = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

const CATEGORY_ORDER = [
  "Humanities & Social Science",
  "Math & Science",
  "Language",
  "Art",
  "Interdisciplinary",
  "General",
  "Stem Foundational Concepts",
];

const getSortOrderFromCategoryName = (categoryName) =>
  CATEGORY_ORDER.findIndex((category) => categoryName.match(new RegExp(category, "gi")));

export const arrangeByCategory = (subs) => {
  const subjects = subs
    .filter((sub) => sub.active)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((s) => {
      const numStreams = s.numStreams || 0;
      const numResources = s.numResources || 0;

      return {
        id: s.id,
        name: s.name,
        category: s.category,
        order: Number(s.order) || 0,
        numStreams,
        numResources,
        slug: s.slug,
        imageSm: s.imageSm,
        topics: s.topics,
        emoji: s.emoji,
        exams: s.exams || [],
        color: s.color || "#01A0B6",
        ...s,
      };
    });

  const categories = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const subject of subjects) {
    if (subject.category) {
      if (Object.prototype.hasOwnProperty.call(categories, subject.category)) {
        categories[subject.category].push(subject);
      } else {
        categories[subject.category] = [subject];
      }
    } /* else if (Object.prototype.hasOwnProperty.call(categories, "undefined")) {
      // categories["undefined"].push(subject);
    } else {
      // categories["undefined"] = [subject];
    } */
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(categories)) {
    // uncomment the line below if you want to sort by the order specified in firestore for each subject
    // categories[key] = categories[key].sort(dynamicSort("order"));

    // sort alphabetically
    categories[key] = categories[key].sort();
  }

  const ordered = {};
  Object.keys(categories)
    // .sort((a, b) => categories[b][0].order - categories[a][0].order)
    .sort((a, b) => getSortOrderFromCategoryName(a) - getSortOrderFromCategoryName(b))
    .forEach((key) => {
      ordered[key] = categories[key];
      return ordered[key];
    });

  return ordered;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getSubjectShortName = (subject) => ((subject || {}).slug || "").replace(/-/gi, " ");

// const reNoSpecialChars = (s) => s.replace(/[^a-zA-Z0-9 ]/giu, "");

export const videoTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds - mins * 60;
  return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
};

export const totalCoverageInSeconds = (doc, stretches) => {
  // Taking doc and stretches gives this function flexibility and
  // allows for sending just session arrays or entire docs to turn
  // into session arrays (called segments below)

  let segments = [];
  if (stretches) {
    segments = stretches;
  } else if (doc) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(doc)) {
      // Check for OLD storage way, where the document object
      // had a "sessions" array like { end: NUM, live: BOOL, start: NUM}
      if (key === "sessions") {
        const toAdd = doc[key].filter((s) => !s.live).reduce((a, c) => [...a, ...c.stretches], []);
        segments = [...segments, ...toAdd];

        // Check for NEW storage way, where the document object
        // has multiple "session.DATE_IN_MS" maps like
        // { end: DATE, live: BOOL, start: DATE, stretches: ARRAY([...{ end: NUM, start: NUM}])}
      } else if (key.indexOf("session.") > -1 && !doc[key].live) {
        segments = [...segments, ...doc[key].stretches];
      }
    }
  }

  if (segments.length > 0) {
    const segs = segments.sort((a, b) => a.start - b.start || a.end - b.end);
    let coverage = segs[0].end - segs[0].start;

    if (segs.length > 1) {
      for (let i = 1; i < segs.length; i++) {
        const prevMaxEnd = segs.slice(0, i).sort(dynamicSort("-end"))[0].end;

        if (segs[i].end > prevMaxEnd) {
          if (segs[i].start > prevMaxEnd) {
            coverage += segs[i].end - segs[i].start;
          } else {
            coverage += segs[i].end - prevMaxEnd;
          }
        }
      }
    }
    return coverage;
  }
  return 0;
};

export const CopyToClipboard = (text) => {
  const textField = document.createElement("textarea");
  textField.innerText = text.trim();
  textField.value = text.trim();
  document.body.appendChild(textField);
  textField.select();
  const selection = document.getSelection();
  const range = document.createRange();
  range.selectNode(textField);
  selection.removeAllRanges();
  selection.addRange(range);
  document.execCommand("copy");
  selection.removeAllRanges();
  document.body.removeChild(textField);
};

export const triviaResultPath = (id) => `/trivia/${id}/view`;

const algoliaClientNoCache = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
  {
    responsesCache: createNullCache(),
    // requestsCache: createInMemoryCache({ serializable: false }), // or createNullCache()
  }
);
export const algoliaCreatorLabProjectsIndex = algoliaClientNoCache.initIndex(
  `${meta.algoliaIndexEnv}_CREATOR_PROJECTS`
);

const getAllSubstrings = (query) => {
  const formattedQuery = query.split(" ").map((word) => word.toLowerCase());
  const allSubstrings = [];
  let currentSubstring = "";

  // code adapted from https://algorithms.tutorialhorizon.com/print-all-subarrays-of-a-given-array/
  for (let i = 0; i < formattedQuery.length; i++) {
    for (let j = i; j <= formattedQuery.length; j++) {
      for (let k = i; k < j; k++) {
        currentSubstring += `${formattedQuery[k]} `;
      }
      allSubstrings.push(currentSubstring.trim());
      currentSubstring = "";
    }
  }

  return allSubstrings.filter((substring) => !!substring); // the filter is to remove empty strings
};

// eslint-disable-next-line consistent-return
export const getFilters = (query, filters, subs) => {
  const substrings = getAllSubstrings(query);
  const foundAliases = filters.filter((alias) => substrings.includes(alias.toLowerCase()));

  if (foundAliases.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const subject of subs) {
      const { aliases } = subject;
      const alias = aliases.find((a) => foundAliases.includes(a));
      if (alias) {
        return {
          subject: aliases[aliases.length - 1].replace(/\s/g, "-").toLowerCase(),
          alias,
        }; // TODO: add functionality for multiple subjects, but this would be a really niche search
      }
    }
  } else {
    return {};
  }
};

export const readableNumber = (num) => {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(2).replace(/\.0$/, "")}B`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(2).replace(/\.0$/, "")}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, "")}k`;
  }
  return num;
};

/*
const getHasuraLinkObj = (notif) => {
  switch (notif?.type) {
    case "update_community":
      return {
        item: notif?.community,
        type: "community",
      };
    case "update_group":
    case "delete_resource":
    case "add_resource":
    case "chat":
    case "invite":
      return {
        item: notif?.group,
        type: "group",
      };
    case "mention":
      return {
        item: notif?.group || notif?.post,
        type: notif?.group ? "group" : "post",
      };
    case "post":
    case "comment":
      return {
        item: notif?.post,
        type: "post",
      };
    default:
      return { item: null, type: null };
  }
}; */

// eslint-disable-next-line max-lines-per-function
/* export const getNotificationInfo = (notification, baseInfo = {}) => {
  const { href, as, emoji, label } = getHasuraLinkAndInfo(getHasuraLinkObj(notification));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const info: any = { ...baseInfo };

  info.href = href;
  info.as = as;
  info.emoji = emoji;
  info.subtitle = label;

  switch (notification?.type) {
    case "update_community": {
      info.icon = ["fas", "user-friends"];
      info.title = notification?.title || "Community info has been changed";
      return false;
    }
    case "update_group": {
      info.icon = ["fas", "comments-alt"];
      info.title = notification?.title || "Group info has been changed";
      return false;
    }
    case "follow": {
      info.img = notification?.userByActionUserId?.avatar_url;
      info.href = "/u/[handle]";
      info.as = `/u/${notification?.userByActionUserId?.handle}`;
      info.title = notification?.title || " followed you";
      return true;
    }
    case "delete_resource": {
      info.icon = ["fas", "comments-alt"];
      const resourceObj = getResourceIcon(notification?.resource?.type);
      info.resourceIcon = [resourceObj.prefix, resourceObj.iconName];
      info.title = notification?.title || " has deleted a resource";
      return true;
    }
    case "add_resource": {
      info.icon = ["fas", "comments-alt"];
      const resourceObj = getResourceIcon(notification?.resource?.type);
      info.resourceIcon = [resourceObj.prefix, resourceObj.iconName];
      info.title = notification?.title || " has added a resource";
      return true;
    }
    case "post": {
      info.subtitle = "post";
      info.icon = ["fas", "user-friends"];
      info.title = notification?.title || " has added a post";
      info.desc = notification?.post?.title;
      if (notification?.community) {
        info.subtitle = notification?.community?.name;
        info.emoji = notification?.community?.emoji;
      }
      if (Number(notification?.desc)) {
        info.title = " has upvoted your post";
        info.desc = `${notification?.post?.title} now has ${notification?.desc} upvotes`;
      }

      return true;
    }
    case "comment": {
      info.subtitle = "comment";
      info.icon = ["fas", "user-friends"];
      info.title = notification?.title || " has added a comment";
      if (notification?.community) {
        info.subtitle = notification?.community?.name;
        info.emoji = notification?.community?.emoji;
      }
      if (Number(notification?.desc)) {
        info.title = " has upvoted your comment";
        info.desc = `it now has ${notification?.desc} upvotes`;
      }
      return true;
    }
    case "chat": {
      info.icon = ["fas", "comments-alt"];
      info.desc = notification.desc === null ? "" : `"${notification?.desc}"`;
      // info.as = assertAbstractType;
      info.title = notification?.title || " sent a message";
      if (Object.prototype.hasOwnProperty.call(notification, "contents")) {
        if (notification?.contents?.add_chat) {
          if (notification.contents.add_chat.length > 1) {
            const length = notification.contents.add_chat.length - 1;
            info.desc = `${length} other messages were also sent`;
          }
        }
      }
      return true;
    }
    case "invite": {
      if (Object.prototype.hasOwnProperty.call(notification?.contents, "invite")) {
        info.icon = ["fas", "comments-alt"];
        info.desc = notification.desc || "";
        info.title = notification?.title || ` invited you to join a group!`;
        info.action_button = { type: "invite" };
        if (notification?.action_user_id === null) {
          info.title = "you were invited to join a group!";
          return false;
        }
      }
      return true;
    }
    case "mention": {
      if (Object.prototype.hasOwnProperty.call(notification?.contents, "mention")) {
        info.subtitle = notification?.group?.name
          ? notification?.group?.name
          : notification?.contents?.mention?.[0]?.community_name;
        info.icon = ["fas", "at"];
        info.desc = notification.desc || "";
        info.emoji = notification?.emoji || emoji;
        info.title = notification?.title || ` mentioned you!`;
        if (notification?.action_user_id === null) {
          info.title = "you were mentioned!";
          return false;
        }
      }
      return true;
    }
    default: {
      info.subtitle = "";
      info.emoji = "❗";
      return false;
    }
  }
}; */

export const orderUnits = (units: Unit[]) => {
  const otherUnits: Unit[] = [];
  const namedUnits: Unit[] = [];
  const cramUnits: Unit[] = [];
  units?.forEach((unit) => {
    if (/cram/i.test(unit.name)) {
      cramUnits.push(unit);
    } else if (unit.name?.substring(0, 4).toLowerCase() === "unit") {
      namedUnits.push(unit);
    } else {
      otherUnits.push(unit);
    }
  });

  return [...namedUnits, ...otherUnits, ...cramUnits].sort((a, b) => a.order - b.order);
};

// eslint-disable-next-line consistent-return
export const validatePassword = (password) => {
  const hasLetters = password.match(/[a-zA-Z]/gi);
  // const hasSymbols = password.match(/(?=.*[ -\/:-@\[-`{-~]{1,})/gi);
  const hasSymbols = password.match(/(?=.*[ -/:-@[-`{-~]{1,})/gi);
  if (password.length > 0) {
    if (password.length < 8) {
      return "Your password must be at least 8 characters long";
    }
    if (!hasLetters && !hasSymbols) {
      return "Your password must contain at least one letter and one special character.";
    }
    if (!hasLetters) {
      return "Your password must contain at least one letter.";
    }
    if (!hasSymbols) {
      return "Your password must contain at least one special character.";
    }
  }
};

export const cleanStringOfEmailsAndConvertToArray = (emails) =>
  // replace spaces and commas with \n
  // then split based on the \n chars
  // trim each email in the array to remove leading/trailing whitespace
  // filter out any elements in the array that ended up becoming solely whitespace
  // profit
  emails
    .replace(/ /g, "\n")
    .replace(/,/g, "\n")
    .split("\n")
    .filter((x) => !!x)
    .map((e) => e.trim().toLowerCase());

export const getCramType = (type) => {
  switch (type) {
    case "cram_pass":
      return "Cram Pass";
    case "cram_session":
      return "Cram Session";
    case "cram_finale":
      return "Cram Finale";
    case "feedback_pass":
      return "Feedback Pass";
    default:
      return "N/A";
  }
};

export const getRedeemLink = (code) => `${meta.baseUrl}/crams?access_code=${code}`;

export const getAccountRole = (account) => {
  if (!account) return;
  if (account.educator_role) {
    // eslint-disable-next-line consistent-return
    return account.educator_role;
  }
  if (account.is_teacher) {
    // eslint-disable-next-line consistent-return
    return "teacher";
  }
  const role = account.permissions?.role;
  // eslint-disable-next-line consistent-return
  return role === "guest" ? "student" : role;
};
export const getShopifyRole = (role) => {
  switch (role) {
    case "student":
      return "shopify-student";
    case "parent":
      return "shopify-parent";
    case "teacher":
      return "shopify-educator";
    default:
      return "po-educator";
  }
};

export const formatDiscountCodes = (ordersArray) => {
  const discounts = [];
  // eslint-disable-next-line consistent-return
  ordersArray?.forEach((order) => {
    if (!order) return null;
    if (order.discount_codes) discounts.push(order.discount_codes);
  });

  const flattenedDiscounts = discounts.flat();
  return Array.from(new Set(flattenedDiscounts));
};

// Returns the active units for the subject
export const getSubjectUnits = (subject) => subject?.units?.filter((u) => u.active) ?? [];

// I don't think our slugs are ever arrays, but typescript likes to remind us they could be so this solves for that
export const getRouterSlugString = (slug) => {
  return Array.isArray(slug) ? slug[0] : slug;
};
